import moment from 'moment';
import Constants from './constants';
import UserImg from '../assets/user.svg';

export const formatPhNo = (val) => {
  if (val.startsWith('+')) return val;
  else if (val.startsWith('03')) return '+92' + val.split('0')[1];
  else return '+' + val;
};

export const getQueryParamParsed = (value) => {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  return value;
};

export const formatDecimalHours = (val) => {
  const MINS = [
    { text: '00', value: '.00' },
    { text: '15', value: '.25' },
    { text: '30', value: '.50' },
    { text: '45', value: '.75' },
  ];

  let hours = val?.toString().split('.')[0];
  let mins = val?.toString().split('.')[1];

  if (mins?.length === 1) mins = mins * 10; //checking if minutes have length is 1 then adding 0 at end

  mins = MINS.filter((d) => d.value === `.${mins}`)[0]?.text;

  return {
    hours: hours,
    minutes: mins ?? '00',
  };
};

export const formatShiftTimings = (shift) => {
  var getDay = function (i) {
    switch (i) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return;
    }
  };

  let formattedShift = {};
  for (let i = 0; i < shift.length; i++) {
    formattedShift[getDay(i)] = {
      start_time: shift[i].start_time,
      end_time: shift[i].end_time,
      holiday: shift[i].holiday,
      overtime: shift[i].overtime,
      overtime_hours: shift[i].overtime_hours,
      is_flexible: shift[i].is_flexible,
      hours: shift[i].hours,
    };
  }

  return formattedShift;
};

const getRenderableExperience = (duration, includeExpString = true) => {
  let years = Math.round(duration.asYears());
  let months = Math.round(duration.asMonths());

  if (months < 1) {
    return `Fresh `;
  } else if (years < 1) {
    return `${months} Months`;
  } else {
    if (years > 0 && years <= 1) {
      return `1 Year ${includeExpString ? 'Experience' : ''}`;
    } else if (years >= 1 && years < 2) {
      return `1+ Years ${includeExpString ? 'Experience' : ''}`;
    } else if (years >= 2 && years < 3) {
      return `2+ Years ${includeExpString ? 'Experience' : ''}`;
    } else if (years >= 3 && years < 4) {
      return `3+ Years ${includeExpString ? 'Experience' : ''}`;
    } else if (years >= 4 && years < 5) {
      return `4+ Years ${includeExpString ? 'Experience' : ''}`;
    } else return `5+ Years ${includeExpString ? 'Experience' : ''}`;
  }
};

export const getLatestEducationSmallView = (data) => {
  data.sort((a, b) => moment(a.completed_date, 'YYYY-MM-DD') - moment(a.completed_date, 'YYYY-MM-DD'));

  return {
    name: data?.[0]?.degree_type?.name ?? 'NA',
    date: moment(data?.[0]?.completed_date).format('DD MMM YYYY'),
  };
};

export const getLatestEducation = (data) => {
  data.sort((a, b) => moment(a.completed_date, 'YYYY-MM-DD') - moment(a.completed_date, 'YYYY-MM-DD'));

  return data?.[0]?.degree_title?.name ?? 'NA';
};

export const calculateExperienceFromJSON = (text = '', includeExpString = true) => {
  let daysText = text.split(':');
  let experiences = moment.duration(0);

  daysText.forEach((item) => {
    let [e1, e2] = item.split(',');
    e1 = moment(e1, 'YYYY-MM-DD');

    if (e2 === 'current_date') e2 = moment();
    else e2 = moment(e2, 'YYYY-MM-DD');

    // if(e2 === 'current_date')
    //   e2= moment('YYYY-DD-MM')
    experiences.add(e2.diff(e1));
  });

  return getRenderableExperience(experiences, includeExpString);
};

export const calculateExperienceFromList = (experiences = [], includeExpString = true) => {
  let expDuration = moment.duration(0);
  experiences.forEach((item) => {
    let start = moment(item.start_date);
    let end = item.end_date ? moment(item.end_date) : moment();
    expDuration.add(end.diff(start));
  });

  return getRenderableExperience(expDuration, includeExpString);
};

export const getEmployeeImagePath = (path, role = 'employee', type) => {
  let url_role = role ? `${role}/` : '';
  let url_type = type ? `${type}/` : '';
  if (path) return `${Constants.apiImagesURL}${url_role}${url_type}${path}`;
  return UserImg;
};

export const getImagePath = (path, role, type) => {
  if (path) {
    let url_role = role ? `${role}/` : '';
    let url_type = type ? `${type}/` : '';
    return `${Constants.apiImagesURL}${url_role}${url_type}${path}`;
  }
};

// TODO: Sort by starting date
export const dateSorter = (a, b) => {
  if (b.endDate === null) {
    return 1;
  }
  if (a.endDate === null) {
    return -1;
  }
  return moment(b.endDate) - moment(a.endDate);
};

export const getWorkingDays = (daysIndices) => {
  let daysString = daysIndices.map((item, index) => {
    switch (item) {
      case 0:
        return 'Mon';
      case 1:
        return 'Tue';
      case 2:
        return 'Wed';
      case 3:
        return 'Thu';
      case 4:
        return 'Fri';
      case 5:
        return 'Sat';
      case 6:
        return 'Sun';
      default:
        return 'Mon';
    }
  });
  return daysString.join(', ');
};

export function getKeyByValue(object, value) {
  for (const [key, value2] of Object.entries(object)) {
    if (value2 === value) return parseInt(key);
  }
  return 0;
}

export const getAutocompleteValue = (list, match_id) => {
  let item = list?.filter((item) => item.id === match_id)[0];
  return item === undefined ? null : item;
};

export const getUrlForNotification = (data, current_user) => {
  //setting up prefix
  let prefix = `/${current_user.role}/`;
  //setting up urls
  if (data.type === 'Registered') {
    if (data.user_type === 'employees') return `${prefix}applicants/${data.user_id}`;
    else if (data.user_type === 'employers') return `${prefix}employers/${data.user_id}`;
  } else if (data.notification_type === 'JobApplied') {
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=2`;
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=2`;
  } else if (data.notification_type === 'Shortlisted') {
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=2`;
    else if (current_user.role === 'employee') return `${prefix}jobs/${data.current_model.employer_job_id}`;
  } else if (data.notification_type === 'InterviewApproved') {
    // correct employee and employer
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=2`;
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=2`;
    else if (current_user.role === 'employee') return `${prefix}interviews?tab=2`;
  } else if (data.notification_type === 'InterviewScheduled') {
    // correct for employee
    if (current_user.role === 'employee') return `${prefix}interviews?tab=2`;
    if (current_user.role === 'admin') return `${prefix}interviews`;
  } else if (data.notification_type === 'Interviewed') {
    // correct for employee
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=3`;
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=2`;
    else if (current_user.role === 'employee') return `${prefix}interviews?tab=3`;
  } else if (data.notification_type === 'InterviewReschedule') {
    if (current_user.role === 'employee') return `${prefix}interviews?tab=2`;
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=2`;
  } else if (
    data.notification_type === 'CounterOffer' ||
    data.notification_type === 'OfferSent' ||
    data.notification_type === 'OfferAccepted'
  ) {
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=3`;
    // corrected for employer
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=4`;
    // correct for employee
    else if (current_user.role === 'employee') return `${prefix}joboffers`;
  } else if (data.notification_type === 'EmployeeHired' || data.notification_type === 'Joined') {
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.employer_job_id}?tab=4`;
    else if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=4`;
    else if (current_user.role === 'employee') return `${prefix}myjobs`;
  } else if (data.notification_type === 'EmployeeLeaveApplied') {
    if (current_user.role === 'admin') return `${prefix}leavesapplications`;
    else if (current_user.role === 'employer') return `${prefix}leaves`;
  } else if (data.notification_type === 'Resign') {
    if (current_user.role === 'admin') return `${prefix}resignations`;
    else if (current_user.role === 'employer') return `${prefix}employees?status=0`;
    else if (current_user.role === 'employee') return `${prefix}myjobs`;
  } else if (data.notification_type === 'JobProfileReview') {
    if (current_user.role === 'employee') return `${prefix}profile`;
  } else if (data.notification_type === 'RefundRequest') {
    if (current_user.role === 'admin') return `${prefix}finance/employer-refund`;
  } else if (data.notification_type === 'Attendance') {
    if (current_user.role === 'employer') return `${prefix}employees/${data.current_model.id}?tab=1`;
    else if (current_user.role === 'admin') return `${prefix}employees/${data.current_model.id}?tab=1`;
  } else if (data.notification_type === 'NotEnoughFunds') {
    if (current_user.role === 'employer') return `${prefix}addfunds`;
  } else if (data.notification_type === 'FundsNotification') {
    if (current_user.role === 'employer') return `${prefix}addfunds`;
    else if (current_user.role === 'admin') return `${prefix}employers/${data.current_model.user_id}?tab=3`;
    else if (current_user.role === 'employee') return `${prefix}wallet`;
  } else if (data.notification_type === 'Dispute') {
    if (current_user.role === 'admin') return `${prefix}disputes`;
    else if (current_user.role === 'employer') return `${prefix}employees/${data.current_model.id}?tab=1`;
    else if (current_user.role === 'employee') return `${prefix}attendance`;
  } else if (data.notification_type === 'Termination') {
    if (current_user.role === 'admin') return `${prefix}terminations`;
    else if (current_user.role === 'employer') {
      if (data.current_model.status === '9') {
        return `${prefix}employees/${data.current_model.id}`;
      } else {
        return `${prefix}employees?status=0`;
      }
    } else if (current_user.role === 'employee') return `${prefix}myjobs`;
  } else if (data.notification_type === 'Resigned') {
    if (current_user.role === 'admin') return `${prefix}resignations`;
    else if (current_user.role === 'employer') return `${prefix}employees?status=1`;
    else if (current_user.role === 'employee') return `${prefix}myjobs`;
  } else if (data.notification_type === 'ShiftAdded') {
    if (current_user.role === 'employee') return `${prefix}attendance`;
    else if (current_user.role === 'admin') return `${prefix}employees/${data.current_model.id}?tab=1`;
  } else if (data.notification_type === 'ShiftSetting') {
    if (current_user.role === 'employee') return `${prefix}myjobs`;
    else if (current_user.role === 'admin') return `${prefix}employees/${data.current_model.id}?tab=1`;
  } else if (data.notification_type === 'DisableCheckIn') {
    if (current_user.role === 'employee') return `${prefix}myjobs`;
    else if (current_user.role === 'admin') return `${prefix}employees/${data.current_model.id}?tab=1`;
  } else if (data.notification_type === 'EnableCheckIn') {
    if (current_user.role === 'employee') return `${prefix}myjobs`;
    else if (current_user.role === 'admin') return `${prefix}employees/${data.current_model.id}?tab=1`;
  } else if (data.notification_type === 'PostJob') {
    if (current_user.role === 'admin') return `${prefix}jobpostings/${data.current_model.id}`;
  } else if (data.notification_type === 'Refund') {
    if (current_user.role === 'employer') return `${prefix}wallet`;
    else if (current_user.role === 'admin') return `${prefix}finance/employee-wallet/${data.user_id}`;
  } else if (data.notification_type === 'OfferRejected') {
    if (current_user.role === 'employer') return `${prefix}jobdetail/${data.current_model.employer_job_id}?tab=3`;
  } else if (data.notification_type === 'Prescreening') {
    if (current_user.role === 'admin') return `${prefix}pre-screening`;
    if (current_user.role === 'employee') return `${prefix}interviews?tab=0`;
  }
};

export const getAppliedJobStatus = (status) => {
  switch (status) {
    case 0:
      return 'Applied';
    case 1:
      return 'Shortlisted';
    case 2:
      return 'Interview Scheduled';
    case 3:
      return 'Interviewed';
    case 4:
      return 'Offer Sent';
    case 5:
      return 'Offer Accepted';
    case 6:
      return 'Offer Rejected';
    case 7:
      return 'Counter Offer';
    case 8:
      return 'Hired';
    case 9:
      return 'Joined';
    case 10:
      return 'Closed';
    case 11:
      return 'Terminated';
    case 12:
      return 'Resigned';
    case 13:
      return 'Disabled';
    case 14:
      return 'Suspended';
    case 15:
      return 'Interview Expired';
    case 16:
      return 'Offer Expired';
    case 17:
      return 'Hiring Rejected';
    default:
      return 'NA';
  }
};
