import * as ActionTypes from '../actionTypes';

export const user = (state = { user: null, role: null, isVerified: false, isVerifying: true }, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return { ...state, ...action.payload };
    case ActionTypes.UPDATE_USER:
      let newUser = { ...state.user, ...action.payload.user };

      localStorage.setItem('user', JSON.stringify(newUser));
      // start pusher channel
      return { ...state, user: newUser };
    case ActionTypes.CLEAR_USER:
      return { user: null, role: null, isVerified: false, isVerifying: false };
    default:
      return state;
  }
};
