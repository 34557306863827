import * as ActionTypes from "./actionTypes";

// Drawer related actions
export const setDrawerExpand = (value) => {
  return {
    type: ActionTypes.SET_DRAWER_EXPAND,
    payload: value,
  };
};

export const toggleDrawerExpand = () => {
  return {
    type: ActionTypes.TOGGLE_DRAWER_EXPAND,
  };
};

// User related actions
export const setUser = (value) => {
  return {
    type: ActionTypes.SET_USER,
    payload: value,
  };
};

export const updateUser = (value) => {
  return {
    type: ActionTypes.UPDATE_USER,
    payload: value,
  };
};

export const clearUser = () => {
  return {
    type: ActionTypes.CLEAR_USER,
  };
};

// checkin related actions
export const toogleCheckin = (attendance_id) => {
  return {
    type: ActionTypes.TOOGLE_CHECKIN,
    payload: attendance_id,
  };
};

// Employee Specific actions
export const setEmployeeInfo = (value) => {
  return {
    type: ActionTypes.SET_EMPLOYEE_INFO,
    payload: value,
  };
};

export const setBalance = (value) => {
  return {
    type: ActionTypes.SET_BALANCE,
    payload: value,
  };
};

export const setAppliedJob = (value) => {
  return {
    type: ActionTypes.SET_APPLIEDJOB_ID,
    payload: value,
  };
};

export const setAttendanceId = (value) => {
  return {
    type: ActionTypes.SET_ATTENDANCE_ID,
    payload: value,
  };
};

export const setCheckinTime = (value) => {
  return {
    type: ActionTypes.SET_CHECKIN_TIME,
    payload: value,
  };
};

export const setCheckinStatus = (value) => {
  return {
    type: ActionTypes.SET_CHECKIN_STATUS,
    payload: value,
  };
};

export const clearEmployee = (value) => {
  return {
    type: ActionTypes.CLEAR_EMPLOYEE,
  };
};

// employer specific actions
export const setEmployerInfo = (value) => {
  return {
    type: ActionTypes.SET_EMPLOYER_INFO,
    payload: value,
  };
};

export const clearEmployer = (value) => {
  return {
    type: ActionTypes.CLEAR_EMPLOYER,
  };
};

// Notification Actions
export const addNotifications = (notifications) => {
  return {
    type: ActionTypes.ADD_NOTIFICATIONS,
    payload: notifications,
  };
};

export const markAsRead = (notificationIds) => {
  return {
    type: ActionTypes.MARK_AS_READ,
    payload: notificationIds,
  };
};
export const markAsUnread = (notificationIds) => {
  return {
    type: ActionTypes.MARK_AS_UNREAD,
    payload: notificationIds,
  };
};
export const clearNotifications = (notificationIds, setLoading = false) => {
  return {
    type: ActionTypes.CLEAR_NOTIFICATIONS,
    payload: notificationIds,
    setLoading,
  };
};

export const removeNotifications = (notificationIds) => {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION,
    payload: notificationIds,
  };
};
export const clearNotificationsOnLogout = (params) => {
  return {
    type: ActionTypes.CLEAR_NOTFICATIONS_LOGOUT,
  };
};
