import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { withStyles, Typography } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";

function PhoneComponent(props) {
  let { classes, hasError, country, name, value, onChange, onBlur, disabled, required, errorMessage } = props;
  return (
    <div>
      <PhoneInput
        specialLabel="Phone *"
        country={country}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isValid={!hasError}
        containerClass={clsx(disabled && "disabledPhone")}
        className={clsx(disabled && "disabledPhone")}
        disabled={disabled}
        inputProps={{ name: name, required: required ? true : false }}
      />
      {hasError && (
        <Typography className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}

PhoneComponent.propTypes = {
  classes: PropTypes.shape({ errorMessage: PropTypes.any }),
  country: PropTypes.any,
  errorMessage: PropTypes.any,
  hasError: PropTypes.any,
  name: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  required: PropTypes.any,
  value: PropTypes.any
};

const materialStyles = (theme) => ({
  errorMessage: {
    display: "inline-block",
    color: "#f44336",
    marginLeft: 2,
    marginTop: 3,
    marginRight: 14,
    fontSize: "0.75rem"
  }
});

export default withStyles(materialStyles)(PhoneComponent);