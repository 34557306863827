import React, { Component } from "react";
import clonedeep from "lodash.clonedeep";
import { runValidator } from "../../utils/validations";
import {
  Typography,
  TextField,
  ButtonBase,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withStyles, withWidth } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CustomCheckbox from "../CustomCheckbox";
import {
  loginEmployee,
  loginEmployer,
  loginAdmin,
  googleOAuth,
  sendCode,
} from "../../apis/user";
import { updateAuthorizationToken } from "../../utils/axios";
import { connect } from "react-redux";
import {
  setUser,
  setEmployeeInfo,
  setEmployerInfo,
} from "../../redux/actionCreators";
import StyledContainer from "../AuthScreen/";
import FullscreenLoader from "../FullscreenLoader";
import PasswordResetModal from "../PasswordResetModal";
import VerifyCode from "../Modals/verifyCode";
import { Helmet } from "react-helmet";
import { getAppSettings } from "apis/admin";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isRemember: true,
      showPassword: false,
      resend_time: 0,
      resend_time_limit: 0,
      isCodeSending: false,
      isCodeVerifying: false,
      codeVerifyModal: false,
      errors: {},
      submitMessage: false,
      isSubmitLoading: false,
      role: this.getRole(),
      isFullScreenLoading: false,
      googleProblem: false,
      showPasswordReset: false
    };
    this.timer = null;
    this.INPUTS = {
      email: {
        rules: { required: true, email: true, maxLength: 254 },
        errorMessage: "Provide an email address",
      },
      password: {
        rules: { required: true },
        errorMessage: "Provide a password"
      }
    };
  }

  componentDidMount = () => {

    getAppSettings().then((result) => {
      result.forEach((item) => {
        if (item.key === "resend_time")
          this.setState({ resend_time_limit: parseInt(item.value) ?? 30 })
      });
    })
  }

  getRole = () => {
    let queryParams = queryString.parse(this.props.location.search);
    let role = queryParams.role !== undefined ? queryParams.role : "";
    switch (role.toLowerCase()) {
      case "employer":
        return "employer";
      case "admin":
        return "admin";
      default:
        return "employee";
    }
  };

  // VALIDATIONS PART
  handleValidation = (e) => {
    let errors = runValidator(e.target.value, this.INPUTS[e.target.name]);

    if (errors.length > 0) {
      let uppdatedErrors = clonedeep(this.state.errors);
      this.setState({
        errors: { ...uppdatedErrors, [e.target.name]: errors },
      });
    } else {
      let uppdatedErrors = clonedeep(this.state.errors);
      delete uppdatedErrors[e.target.name];
      this.setState({ errors: uppdatedErrors });
    }
  };

  validateAll = () => {
    let errors = {};
    for (let field in this.INPUTS) {
      let fieldErrors = runValidator(this.state[field], this.INPUTS[field]);
      if (fieldErrors.length > 0) errors[field] = fieldErrors;
    }
    return errors;
  };

  clearError = (e) => {
    if (this.state.errors[e.target.name]) {
      let uppdatedErrors = clonedeep(this.state.errors);
      delete uppdatedErrors[e.target.name];
      this.setState({ errors: uppdatedErrors });
    }
  };

  clearSubmitMessage = () => {
    let newState = clonedeep(this.state);
    delete newState.submitMessage;
    this.setState({ submitMessage: newState.submitMessage });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInputToogle = (checkbox) => {
    this.setState({ [checkbox]: !this.state[checkbox] });
  };

  handleRoleSwitch = () => {
    let role = this.state.role === "employee" ? "employer" : "employee";
    // update query params
    let queryParams = queryString.parse(this.props.location.search);
    queryParams.role = role;
    this.props.history.push(`${window.location.pathname}?${queryString.stringify(queryParams)}`);
    this.setState({ role });
  };

  handleLoginClick = () => {
    let errors = this.validateAll();
    if (Object.keys(errors).length !== 0) {
      this.setState({ errors: errors });
    } else {
      this.setState({ isSubmitLoading: true, submitMessage: false });
      // api call and show status
      let callback = null;
      // assign function for login
      switch (this.state.role) {
        case "employer":
          callback = loginEmployer;
          break;
        case "admin":
          callback = loginAdmin;
          break;
        default:
          callback = loginEmployee;
          break;
      }

      callback({ email: this.state.email, password: this.state.password }).then((result) => {
        if (result.status === "success") {
          if (result?.data?.user?.is_2fa_enabled === 1) this.handle2fa()
          else {
            this.setState({ isSubmitLoading: false }, () => this.performLogin(result))
          }
        } else {
          this.setState({
            isSubmitLoading: false,
            errors: result.errors ?? {},
            submitMessage: {
              status: result.status,
              message: result.message
            }
          });
        }
      });
    }
  };

  reduceSecond = () => {
    let resend_time = this.state.resend_time - 1

    this.setState({ resend_time: resend_time })

    if (resend_time === 0) clearInterval(this.timer);
  };

  handle2fa = (resend) => {
    this.setState({ isCodeSending: true })

    if (resend === true) {
      this.setState({ resend_time: this.state.resend_time_limit });
      this.timer = setInterval(this.reduceSecond, 1000);
    }

    sendCode({
      email: this.state.email, type: `${this.state.role}s`
    }).then((result) => {
      if (result?.status !== "success") {
        this.setState({
          isCodeSending: false,
          isSubmitLoading: false,
          codeVerifyModal: false,
          submitMessage: {
            status: "error",
            message: result.message ?? "Some error has occured, please try again."
          }
        });
      } else this.setState({ isSubmitLoading: false, isCodeSending: false, codeVerifyModal: true })
    }).catch((error) => {
      this.setState({
        isCodeSending: false,
        codeVerifyModal: false,
        isSubmitLoading: false,
        submitMessage: {
          status: "error",
          message: error.message ?? "Some error has occured, please try again."
        }
      });
    });
  };

  handleCodeModalClose = () => {
    clearInterval(this.timer)
    this.setState({
      errors: {},
      resend_time: 0,
      codeVerifyModal: false
    })
  }

  handleVerifyCode = (code) => {
    this.setState({ isCodeVerifying: true })

    let params = {
      email: this.state.email,
      password: this.state.password,
      code
    }

    let callback;
    switch (this.state.role) {
      case "employer":
        callback = loginEmployer;
        break;
      default:
        callback = loginEmployee;
        break;
    }

    callback(params).then((result) => {
      if (result.status === "success") {
        this.setState({
          codeVerifyModal: false,
          isCodeVerifying: false,
        }, () => this.performLogin(result));
      } else {
        this.setState({
          isCodeVerifying: false,
          errors: result.errors ?? {}
        });
      }
    }).catch((err) => {
      this.setState({
        codeVerifyModal: false,
        isCodeVerifying: false,
        submitMessage: {
          status: "error",
          message: err.message ?? "Some error has occured, please try again later."
        }
      });
    });
  }

  performLogin = (result) => {
    // add item to localhost
    localStorage.clear();
    localStorage.setItem("user", JSON.stringify(result.data.user));
    localStorage.setItem("user_verif", result.data.token);
    localStorage.setItem("user_role", this.state.role);
    localStorage.setItem("skip_2fa", false);
    // // update token
    updateAuthorizationToken(result.data.token);
    if (this.state.role === "employee")
      this.props.setEmployeeInfo(result.data.employee_info); // wallet info etc
    if (this.state.role === "employer")
      this.props.setEmployerInfo(result.data.card_details); // wallet info etc
    // // dispatch
    this.props.setUser({
      ...result.data,
      subRole: result.data.staff_role,
      role: this.state.role,
      isVerified: true,
      isVerifying: false,
    });

    // redirect
    this.props.history.push(`/${this.state.role}/dashboard`);
  };

  handleLink = (e, link) => {
    e.preventDefault();
    this.props.history.push(link);
  };

  handleGoogleOAuth = (e) => {
    // should show the Whole Screen Loader
    this.setState({ isFullScreenLoading: true });
    let data = {};
    data.type = `${this.state.role}s`;
    data.email = e.profileObj.email;
    data.name = e.profileObj.name;
    data.image = e.profileObj.imageUrl;
    data.googleId = e.profileObj.googleId;
    googleOAuth(data).then((result) => {
      if (result.status === "success") {
        this.setState(
          {
            isFullScreenLoading: false,
            submitMessage: {
              status: result.status,
              message: "Logged in. Redirecting...",
            },
          },
          () => this.performLogin(result)
        );
      } else {
        this.setState({
          isFullScreenLoading: false,
          submitMessage: {
            status: "error",
            message: result.message,
          },
        });
      }
    });
  };

  render() {
    let { classes } = this.props;

    return (
      <StyledContainer
        role={this.state.role}
        handleRoleSwitch={this.handleRoleSwitch}
        text="Login"
      >
        <Helmet>
          <title>Register or Login | Remote Workers Platform | Worktually</title>
        </Helmet>
        {/* Login form */}

        {/* Form fields start */}
        <div className={classes.fieldsContainer}>
          <TextField
            required
            variant="outlined"
            label="Email"
            tabIndex={1}
            name="email"
            onChange={this.handleInputChange}
            onBlur={this.handleValidation}
            onFocus={this.clearError}
            error={this.state.errors.email ? true : false}
            helperText={this.state.errors.email && this.state.errors.email}
            inputProps={{ maxLength: 254, className: classes.input }}
          />
          <TextField
            required
            variant="outlined"
            label="Password"
            tabIndex={2}
            name="password"
            type={this.state.showPassword ? "text" : "password"}
            onChange={this.handleInputChange}
            onBlur={this.handleValidation}
            onFocus={this.clearError}
            error={this.state.errors.password ? true : false}
            helperText={this.state.errors.password && this.state.errors.password}
            inputProps={{ className: classes.input }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" variant="filled" tabIndex={-1}>
                  <IconButton
                    tabIndex={-2}
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityIcon />
                      : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.formFooter}>
            <div className={classes.rememberMe}>
              <CustomCheckbox
                isChecked={this.state.isRemember}
                name="isRemember"
                handleChange={() => this.handleInputToogle("isRemember")}
              />
              <Typography>Keep me logged in </Typography>
            </div>
            <Typography
              className={classes.forgotPasswordText}
              onClick={() => this.setState({ showPasswordReset: true })}
            >
              Forgot Password?
            </Typography>
          </div>

          {this.state.submitMessage && (
            <Alert
              className={classes.submitMessage}
              variant="standard"
              severity={this.state.submitMessage.status}
              onClose={this.clearSubmitMessage}
            >
              {this.state.submitMessage.message}
            </Alert>
          )}
        </div>
        {/* Form Fields End */}

        {/* Login button */}
        <ButtonBase
          type="submit"
          tabIndex={3}
          className={classes.submitButton}
          onClick={this.state.isSubmitLoading ? () => 1 : this.handleLoginClick}
          disabled={this.state.isSubmitLoading}
        >
          {this.state.isSubmitLoading ? (
            <CircularProgress
              style={{ color: "#fff", height: "2.5rem", width: "2.5rem" }}
            />
          ) : (
            <Typography>LOGIN</Typography>
          )}
        </ButtonBase>
        {this.state.role !== "admin" && this.state.googleProblem === false && (
          <div className={classes.socialIcons}>
            {/* <ButtonBase>
              <img src={FacebookIcon} style={{ width: '.8rem' }} alt="" srcset="" />
            </ButtonBase> */}
            {/*           
              <GoogleLogin
                clientId="99900795968-vfq3c40lkaf7mc8fjpc69veqdttng507.apps.googleusercontent.com"
                render = {(renderProps )=>(
                  <ButtonBase onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <img src={GoogleIcon} alt="" srcset="" />
                  </ButtonBase>
                )}
                onSuccess={this.handleGoogleOAuth}
                onFailure={()=>this.setState({googleProblem: true})}
                cookiePolicy={'single_host_origin'}
            /> */}

            {/* <ButtonBase>
              <img src={LinkedinIcon} alt="" srcset="" />
            </ButtonBase> */}
          </div>
        )}

        {this.state.role !== "admin" && this.state.googleProblem === false && (
          <div className={classes.alreadyAccountContainer}>
            <Typography>
              Don't have an account?
              <Link
                className={classes.linkText}
                to={`/register?role=${this.state.role}`}
              >
                &nbsp; Register
              </Link>
            </Typography>
          </div>
        )}
        {/* Images slider */}
        {this.state.isFullScreenLoading === true && <FullscreenLoader />}
        {this.state.showPasswordReset && (
          <PasswordResetModal
            role={`${this.state.role}s`}
            handleClose={() => this.setState({ showPasswordReset: false })}
          />
        )}
        {this.state.codeVerifyModal &&
          <VerifyCode
            open={this.state.codeVerifyModal}
            email={this.state.email}
            resend_time={this.state.resend_time}
            field_errors={this.state.errors}
            isSubmitLoading={this.state.isCodeVerifying}
            handleResendCode={this.handle2fa}
            handleClose={this.handleCodeModalClose}
            handleSubmit={this.handleVerifyCode}
          />
        }
      </StyledContainer>
    );
  }
}

const materialStyles = (theme) => ({
  input: {
    WebkitBoxShadow: "0 0 0 1000px white inset"
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: "2.375rem",
    },
    "& .MuiTextField-root > .MuiFormHelperText-root": {
      marginLeft: 2, marginRight: 0
    }
  },
  formFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  rememberMe: {
    display: "flex",
    "& > :first-child": {
      marginRight: "0.75rem",
    },
  },
  // social icons
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      height: "3.125rem",
      width: "3.125rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #DEE2E6",
      borderRadius: "0.3125rem",
      boxShadow: "3px 4px 4px rgba(150, 168, 219, 0.16)",
      marginRight: "1.5rem",
      "& img": {
        width: "1.3rem"
      }
    },
    "& > *:last-child": {
      marginRight: 0,
    },
  },
  submitMessage: {
    "& .MuiAlert-icon": {
      marginRight: "1rem",
      alignItems: 'center',
      [theme.breakpoints.down(1280)]: {
        fontSize: "22px",
      },
    },
    "& .MuiAlert-message": {
      alignSelf: 'center',
      lineHeight: '1.5rem',
      padding: '0.5rem 0rem'
    }
  },
  alreadyAccountContainer: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  forgotPasswordText: {
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  submitButton: {
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "3.75rem",
    borderRadius: "0.3125rem",
    "& > p": {
      color: "#fff",
    },
  },
  linkText: {
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setEmployeeInfo: (employeeInfo) => dispatch(setEmployeeInfo(employeeInfo)),
    setEmployerInfo: (employerInfo) => dispatch(setEmployerInfo(employerInfo)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(withStyles(materialStyles)(withWidth()(LoginForm))));