import { axios, cachedAxios } from '../utils/axios';
import { getErrors } from '../utils/validations';

export const reportIssues = (type, data) => {
  let result = axios
    .post(`${type}/store-issues`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Issue reported successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: result.message ?? 'Some issue occured',
        };
      }
    })
    .catch((err) => {
      return { status: 'error', message: 'Some problem occured' };
    });
  return result;
};

export const getWhatsNewData = (type, query) => {
  let result = cachedAxios
    .get(`${type}/whatsnew${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some problem occured' };
    });
  return result;
};

export const getTimezones = () => {
  let result = axios
    .get(`timezones`)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Admin logged successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.errors ? getErrors(res.data.errors) : null,
          message: res.meessage,
        };
      }
    })
    .catch((err) => {
      return { status: 'error', message: 'Some problem occured' };
    });
  return result;
};

export const loginAdmin = (data) => {
  let result = axios
    .post(`admin/login`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Admin logged successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data?.message ?? 'Email or password is incorrect',
        };
      }
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Some problem occured. Please check your internet connection and try again later.',
      };
    });
  return result;
};

export const loginEmployee = (data) => {
  let result = axios
    .post(`employee/login`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Employee logged successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data?.errors?.code ? res.data?.errors : res.data?.errors ? getErrors(res.data.errors) : '',
          message: res.data?.message ?? 'Email or password is incorrect',
        };
      }
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Some problem occured. Please check your internet connection and try again later.',
      };
    });
  return result;
};

export const loginEmployer = (data) => {
  let result = axios
    .post(`employer/login`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Employer logged in successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data?.errors?.code ? res.data?.errors : res.data?.errors ? getErrors(res.data.errors) : '',
          message: res.data?.message ?? 'Email or password is incorrect',
        };
      }
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Some problem occured. Please check your internet connection and try again later.',
      };
    });
  return result;
};

export const registerEmployer = (data) => {
  let result = axios
    .post(`employer/register`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Employer registered successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors',
        };
      }
    })
    .catch((err) => {
      return { status: 'error', message: 'Some problem occured' };
    });
  return result;
};

export const registerEmployee = (data) => {
  let result = axios
    .post(`employee/register`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data.data,
          message: 'Employee registered successfully',
        };
      } else {
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message,
        };
      }
    })
    .catch((err) => {
      return { status: 'error', message: 'Some problem occured' };
    });
  return result;
};

export const validateToken = (role, data) => {
  let result = axios
    .get(`${role}/validate-token`, {
      headers: {
        Authorization: 'Bearer ' + data,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

export const googleOAuth = (data) => {
  let result = axios
    .post(`google-signup`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

// data needs email and type
export const findAccount = (data) => {
  let result = axios
    .post(`forget-password`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

export const sendCode = (data) => {
  let result = axios
    .post(`email-reset-code`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

export const verifyCode = (data) => {
  let result = axios
    .post(`reset-password-show`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

export const resetPassword = (data) => {
  let result = axios
    .post(`reset-password`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured' };
    });
  return result;
};

export const getPrescreeningTimeSlots = (query) => {
  let result = axios
    .get(`prescreening-slots${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { result: {}, status: 'failure' };
    });
  return result;
};

export const getInterviewTimeSlots = (query) => {
  let result = axios
    .get(`interview-slots${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { result: {}, status: 'failure' };
    });
  return result;
};
