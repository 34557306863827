import { axios, cachedAxios } from '../utils/axios';
import { getErrors } from '../utils/validations';
import queryString from 'query-string';

export const getLeaveTypesForPopulates = () => {
  let data = axios
    .get(`leaveTypesall?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getCountries = (query) => {
  let data = axios
    .get(`admin/countries${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addCountry = (data) => {
  let result = axios
    .post(`admin/countries`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Country created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Country creation failed' };
    });
  return result;
};

export const editCountry = (id, data) => {
  let result = axios
    .patch(`admin/countries/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Country updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', errors: {}, message: 'Country update failed' };
    });
  return result;
};

export const deleteCountry = (id) => {
  let result = axios
    .delete(`admin/countries/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Country deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const deleteMultiplePopulateValues = (populate, payload) => {
  let result = axios({
    method: 'delete',
    url: `admin/${populate}/0`,
    data: payload,
  })
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: `${populate} deleted successfully`,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while collection deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while collection deleting',
      };
    });
  return result;
};

export const deleteMultipleTemplatesValues = (populate, payload) => {
  const deleteRequests = payload.ids.map((id) => {
    return axios({
      method: 'delete',
      url: `admin/${populate}/${id}`,
    })
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status === 'success') {
          return {
            status: 'success',
            data: res.data.data,
            message: `${populate} ${id} deleted successfully`,
          };
        } else {
          return {
            status: 'error',
            errors: res.data.errors ? getErrors(res.data.errors) : null,
            message: `Issue occurred while deleting ${populate} ${id}`,
          };
        }
      })
      .catch((err) => {
        return {
          status: 'error',
          errors: {},
          message: `Issue occurred while deleting ${populate} ${id}`,
        };
      });
  });

  return Promise.all(deleteRequests);
};

export const restoreMultiplePopulateValues = (populate, payload) => {
  let result = axios({
    method: 'post',
    url: `admin/${populate}/0/restore`,
    data: payload,
  })
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: `${populate} successfully restored!`,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while restoring!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring!',
      };
    });
  return result;
};

export const restoreCountry = (id) => {
  return { status: 'success' };
};

export const getCountriesForPopulates = () => {
  let data = cachedAxios
    .get(`all-countries/all`)
    .then((res) => {
      let countries = res.data.data;
      return countries;
    })
    .catch((err) => {
      return null;
    });
  return data;
};

export const getTransactionCategories = (query) => {
  let data = axios
    .get(`admin/transactionCategory${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });

  return data;
};

export const addTransactionCategory = (data) => {
  return axios
    .post(`admin/transactionCategory`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
};

export const updateTransactionCategory = (data, id) => {
  return axios
    .patch(`admin/transactionCategory/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
};

export const deleteTransactionCategory = (id) => {
  let data = axios
    .delete(`admin/transactionCategory/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const restoreTransactionCategory = (id) => {
  return { status: 'success' };
};

export const getStates = (query) => {
  let data = axios
    .get(`admin/states${query}`)
    .then((res) => {
      let countries = res.data.data;
      return countries;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getStatesForPopulates = (country_id) => {
  if (country_id === null) return new Promise((resolve) => resolve([]));
  let data = axios
    .get(`states?all=1&country_id=${country_id}&sort_by=name&sort_order=asc`)
    .then((res) => {
      let states = res.data.data;
      return states;
    })
    .catch((err) => {
      console.log('Error Occured while fetching States', err);
      return [];
    });
  return data;
};

let cancelToken;
export const getJobIdustrySearchables = (name) => {
  //Check if there are any previous pending requests
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  let data = axios
    .get(`jobIndustriesall?name=${name}`, { cancelToken: cancelToken.token })
    .then((res) => {
      let industries = res.data.data.data;
      return industries;
    })
    .catch((err) => {
      console.log('Error occured while fetching jobs', err);
      return [];
    });
  return data;
};

export const addState = (data) => {
  let result = axios
    .post(`admin/states`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'State created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'State creation failed' };
    });
  return result;
};

export const editState = (id, data) => {
  let result = axios
    .patch(`admin/states/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'State updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('Error occured while updating states', err);
      return { status: 'error', errors: {}, message: 'State update failed' };
    });
  return result;
};

export const deleteState = (id) => {
  let result = axios
    .delete(`admin/states/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'State deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const restoreState = (id) => {
  let result = axios
    .delete(`admin/states/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'State restored successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while restoring',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring',
      };
    });
  return result;
};

// CITIES
export const getCities = (query) => {
  let data = axios
    .get(`admin/cities${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getCitiesForPopulates = (state_id) => {
  if (state_id === null) return new Promise((resolve) => resolve([]));

  let data = axios
    .get(`cities?all=1&state_id=${state_id}`)
    .then((res) => {
      let cities = res.data.data;
      return cities;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getJobIndustryForPopulates = () => {
  let data = axios
    .get(`jobIndustriesall?all=1`)
    .then((res) => {
      let jobIndustries = res.data.data;
      return jobIndustries;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const addCity = (data) => {
  let result = axios
    .post(`admin/cities`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'City created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'City creation failed' };
    });
  return result;
};

export const editCity = (id, data) => {
  let result = axios
    .patch(`admin/cities/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'City updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'City update failed' };
    });
  return result;
};

export const deleteCity = (id) => {
  let result = axios
    .delete(`admin/cities/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'City deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const restoreCity = (id) => {
  let result = axios
    .delete(`admin/cities/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'City restored successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while restoring',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring',
      };
    });
  return result;
};

// JOBTITLES
export const getJobTitles = (query) => {
  let data = axios
    .get(`admin/jobTitles${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addJobTitle = (data) => {
  let result = axios
    .post(`admin/jobTitles`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job titles created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Job Titles creation failed' };
    });
  return result;
};

export const deleteJobTitle = (id) => {
  let result = axios
    .delete(`admin/jobTitles/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Title deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editJobTitle = (id, data) => {
  let result = axios
    .patch(`admin/jobTitles/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job title updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Job title update failed',
      };
    });
  return result;
};

export const viewJobTitle = (id) => {
  return { status: 'success', data: {} };
};

export const restoreJobTitle = (id) => {
  return { status: 'success' };
};

// APPLICANT CATEGORIES
export const getApplicantCategories = (query) => {
  let data = axios
    .get(`admin/applicantStatuses${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addApplicantCategory = (data) => {
  let result = axios
    .post(`admin/applicantStatuses`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Applicant category created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Applicant category creation failed' };
    });
  return result;
};

export const deleteApplicantCategory = (id) => {
  let result = axios
    .delete(`admin/applicantStatuses/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Applicant category deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editApplicantCategory = (id, data) => {
  let result = axios
    .patch(`admin/applicantStatuses/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Applicant status updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Applicant status update failed',
      };
    });
  return result;
};

export const viewApplicantCategory = (id) => {
  return { status: 'success', data: {} };
};

export const restoreApplicantCategory = (id) => {
  return { status: 'success' };
};

// PROMOTION CODES
export const getCouponCodes = (query) => {
  let data = axios
    .get(`admin/coupons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addCouponCode = (data) => {
  let result = axios
    .post(`admin/coupons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Promotion Code created successfully.',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Promotion Code creation failed.' };
    });
  return result;
};

export const deleteCouponCode = (id) => {
  let result = axios
    .delete(`admin/coupons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Promotion Code deleted successfully.',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editCouponCode = (data) => {
  let result = axios
    .patch(`admin/coupons/${data.id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Promotion Code updated successfully.',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors..',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Promotion Code update failed.',
      };
    });
  return result;
};

export const restoreCouponCode = (id) => {
  let result = axios
    .post(`admin/coupons/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Promotion code information restored successfully!',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while restoring',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring',
      };
    });
  return result;
};

// REJECTED REASONS
export const getRejectedReasons = (query) => {
  let data = axios
    .get(`admin/rejectReasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addRejectedReason = (data) => {
  let result = axios
    .post(`admin/rejectReasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Reject reasons created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Reject reason creation failed' };
    });
  return result;
};

export const deleteRejectedReason = (id) => {
  let result = axios
    .delete(`admin/rejectReasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Rejected reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editRejectedReason = (id, data) => {
  let result = axios
    .patch(`admin/rejectReasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Reject reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Reject reason update failed',
      };
    });
  return result;
};

export const restoreRejectedReason = (id) => {
  return 'implementaion pending';
};

export const getRejectedReasonsForPopulates = () => {
  let data = axios
    .get(`/rejectReasonsall?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// Resignation Reasons
export const getResignationReasons = (query) => {
  let data = axios
    .get(`admin/resign-reasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addResignationReason = (data) => {
  let result = axios
    .post(`admin/resign-reasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Resign reason created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Resign reason creation failed' };
    });
  return result;
};

export const deleteResignationReason = (id) => {
  let result = axios
    .delete(`admin/resign-reasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Resign reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editResignationReason = (id, data) => {
  let result = axios
    .patch(`admin/resign-reasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Resign reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Resign reason update failed',
      };
    });
  return result;
};

export const restoreResignationReason = (id) => {
  return 'implementaion pending';
};

export const getResignationReasonsForPopulates = () => {
  let data = axios
    .get(`/resignreasonall?all=1&is_active=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// Delete Reasons
export const getDeleteReasons = (query) => {
  let data = axios
    .get(`admin/deletereasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addDeleteReason = (data) => {
  let result = axios
    .post(`admin/deletereasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Delete reason creation failed' };
    });
  return result;
};

export const deleteDeleteReason = (id) => {
  let result = axios
    .delete(`admin/deletereasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editDeleteReason = (id, data) => {
  let result = axios
    .patch(`admin/deletereasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Delete reason update failed',
      };
    });
  return result;
};

export const restoreDeleteReason = (id) => {
  return 'implementaion pending';
};

export const getDeleteReasonsForPopulates = () => {
  let data = axios
    .get(`/deletereasonsall?all=1&active_status=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// Block Reasons
export const getBlockReasons = (query) => {
  let data = axios
    .get(`admin/blockreasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addBlockReason = (data) => {
  let result = axios
    .post(`admin/blockreasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Delete reason creation failed' };
    });
  return result;
};

export const deleteBlockReason = (id) => {
  let result = axios
    .delete(`admin/blockreasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editBlockReason = (id, data) => {
  let result = axios
    .patch(`admin/blockreasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Delete reason update failed',
      };
    });
  return result;
};

export const restoreBlockReason = (id) => {
  return 'implementaion pending';
};

export const getBlockReasonsForPopulates = (query) => {
  let data = axios
    .get(`/blockreasonsall?all=1&active_status=1${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// Interview Reasons
export const getInterviewReasons = (query) => {
  let data = axios
    .get(`admin/interview-reasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addInterviewReason = (data) => {
  let result = axios
    .post(`admin/interview-reasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Delete reason creation failed' };
    });
  return result;
};

export const deleteInterviewReason = (id) => {
  let result = axios
    .delete(`admin/interview-reasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editInterviewReason = (id, data) => {
  let result = axios
    .patch(`admin/interview-reasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Delete reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Delete reason update failed',
      };
    });
  return result;
};

export const restoreInterviewReason = (id) => {
  return 'implementaion pending';
};

export const getInterviewReasonsForPopulates = (query) => {
  let data = axios
    .get(`/interviewreasonall?all=1&is_active=1${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// Termination Reasons
export const getTerminationReasons = (query) => {
  let data = axios
    .get(`admin/terminate-reasons${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addTerminationReason = (data) => {
  let result = axios
    .post(`admin/terminate-reasons`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Terminate reason created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Terminate reason creation failed' };
    });
  return result;
};

export const deleteTerminationReason = (id) => {
  let result = axios
    .delete(`admin/terminate-reasons/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Terminate reason deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editTerminationReason = (id, data) => {
  let result = axios
    .patch(`admin/terminate-reasons/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Terminate reason updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Terminate reason update failed',
      };
    });
  return result;
};

export const restoreTerminationReason = (id) => {
  return 'implementaion pending';
};

export const getTerminationReasonsForPopulates = () => {
  let data = axios
    .get(`/terminatereasonall?all=1&is_active=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// SOURCES
export const getSourcesForPopulates = (query = '') => {
  let data = cachedAxios
    .get(`joinSourcesall?all=1${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getSources = (query) => {
  let data = axios
    .get(`admin/joinSources${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addSource = (data) => {
  let result = axios
    .post(`admin/joinSources`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Source created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Source creation failed' };
    });
  return result;
};

export const deleteSource = (id) => {
  let result = axios
    .delete(`admin/joinSources/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Source deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editSource = (id, data) => {
  let result = axios
    .patch(`admin/joinSources/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Source updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Reject reason update failed',
      };
    });
  return result;
};

export const restoreSource = (id) => {
  return 'implementaion pending';
};

// EDUCATIONALINSTITUTES
export const getEducationalInstitutes = (query) => {
  let data = axios
    .get(`admin/educationalInstitutes${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getEducationalInstitutesSearchables = () => {
  let data = axios
    .get(`educationalInstitutesall?all=1&active_status=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addEducationalInstitute = (data) => {
  let result = axios
    .post(`admin/educationalInstitutes`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Educational institute created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Educational institute creation failed',
      };
    });
  return result;
};

export const deleteEducationalInstitute = (id) => {
  let result = axios
    .delete(`admin/educationalInstitutes/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Educational institute deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editEducationalInstitute = (id, data) => {
  let result = axios
    .patch(`admin/educationalInstitutes/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Educational institute updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Reject reason update failed',
      };
    });
  return result;
};

export const viewEducationalInstitute = (id) => {
  return { status: 'success', data: {} };
};

export const restoreEducationalInstitute = (id) => {
  return { status: 'success' };
};

// INDUSTRYAREAS
export const getIndustryAreas = (query) => {
  let data = axios
    .get(`admin/jobIndustries${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addIndustryArea = (data) => {
  let result = axios
    .post(`admin/jobIndustries`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Industry created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Job Industry creation failed' };
    });
  return result;
};

export const deleteIndustryArea = (id) => {
  let result = axios
    .delete(`admin/jobIndustries/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Industry deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editIndustryArea = (id, data) => {
  let result = axios
    .patch(`admin/jobIndustries/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Industry updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Reject reason update failed',
      };
    });
  return result;
};

export const viewIndustryArea = (id) => {
  return { status: 'success', data: {} };
};

export const restoreIndustryArea = (id) => {
  return { status: 'success' };
};

// DEGREETYPES
export const getDegreeTypesForPopulates = () => {
  let data = axios
    .get(`degreeTypesall?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getDegreeTypes = (query) => {
  let data = axios
    .get(`admin/degreeTypes${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addDegreeType = (data) => {
  let result = axios
    .post(`admin/degreeTypes`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree type created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Degree type creation failed' };
    });
  return result;
};

export const deleteDegreeType = (id) => {
  let result = axios
    .delete(`admin/degreeTypes/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Type deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editDegreeType = (id, data) => {
  let result = axios
    .patch(`admin/degreeTypes/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Type updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Degree type update failed',
      };
    });
  return result;
};

export const viewDegreeType = (id) => {
  return { status: 'success', data: {} };
};

export const restoreDegreeType = (id) => {
  return { status: 'success' };
};

// DEGREE TITLES
export const getDegreeTitles = (query) => {
  let data = axios
    .get(`admin/degreeTitles${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getDegreeTitlesForPopulates = (degree_type_id) => {
  let data = axios
    .get(`degreeTitlesall?all=1&active_status=1&degree_type_id=${degree_type_id}`)
    .then((res) => {
      let states = res.data;
      return states;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const addDegreeTitle = (data) => {
  let result = axios
    .post(`admin/degreeTitles`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree title created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Degree title creation failed' };
    });
  return result;
};

export const deleteDegreeTitle = (id) => {
  let result = axios
    .delete(`admin/degreeTitles/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Title deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editDegreeTitle = (id, data) => {
  let result = axios
    .patch(`admin/degreeTitles/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Title updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Degree Title update failed',
      };
    });
  return result;
};

export const viewDegreeTitle = (id) => {
  return { status: 'success', data: {} };
};

export const restoreDegreeTitle = (id) => {
  return { status: 'success' };
};

// DEGREESUBJECTS
export const getDegreeSubjects = (query) => {
  let data = axios
    .get(`admin/degreeSubjects${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const getDegreeSubjectsSearchables = () => {
  let data = axios
    .get(`degreeSubjectsall?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addDegreeSubject = (data) => {
  let result = axios
    .post(`admin/degreeSubjects`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree subjects created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Degree type creation failed' };
    });
  return result;
};

export const deleteDegreeSubject = (id) => {
  let result = axios
    .delete(`admin/degreeSubjects/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Subjects deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editDegreeSubject = (id, data) => {
  let result = axios
    .patch(`admin/degreeSubjects/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Degree Subject updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Degree Subject update failed',
      };
    });
  return result;
};

export const viewDegreeSubject = (id) => {
  return { status: 'success', data: {} };
};

export const restoreDegreeSubject = (id) => {
  return { status: 'success' };
};

// LANGUAGES
export const getLanguages = (query) => {
  let data = axios
    .get(`admin/languages${query}`)
    .then((res) => {
      let languages = res.data.data;
      return languages;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const getLanguagesSearchables = () => {
  let data = axios
    .get(`languagesall?all=1`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      // console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addLanguage = (data) => {
  let result = axios
    .post(`admin/languages`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Language creation failed' };
    });
  return result;
};

export const deleteLanguage = (id) => {
  let result = axios
    .delete(`admin/languages/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editLanguage = (id, data) => {
  let result = axios
    .patch(`admin/languages/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Language update failed' };
    });
  return result;
};

export const viewLanguage = (id) => {
  return { status: 'success', data: {} };
};

export const restoreLanguage = (id) => {
  return { status: 'success' };
};

// DEPARRTMENTS
export const getDepartmentsForPopulates = () => {
  let data = axios
    .get(`departmentall?all=1`)
    .then((res) => {
      let departments = res.data.data;
      return departments;
    })
    .catch((err) => {
      //   console.log("error occured");
      return null;
    });
  return data;
};

export const getDepartments = (query) => {
  let data = axios
    .get(`admin/departments${query}`)
    .then((res) => {
      let departments = res.data.data;
      return departments;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const getDepartmentsSearchables = () => {
  let data = axios
    .get(`departmentssall?all=1`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      // console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addDepartment = (data) => {
  let result = axios
    .post(`admin/departments`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Department created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Department creation failed' };
    });
  return result;
};

export const deleteDepartment = (id) => {
  let result = axios
    .delete(`admin/departments/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Department deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editDepartment = (id, data) => {
  let result = axios
    .patch(`admin/departments/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Department updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Language update failed' };
    });
  return result;
};

export const viewDepartment = (id) => {
  return { status: 'success', data: {} };
};

export const restoreDepartment = (id) => {
  return { status: 'success' };
};

// Flags
export const getFlags = () => {
  let data = axios
    .get(`admin/flags?all=1&is_active=1`)
    .then((res) => {
      let flags = res.data;
      return flags;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getSettingsFlags = (query) => {
  let data = axios
    .get(`admin/flags${query}`)
    .then((res) => {
      let flags = res.data;
      return flags;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getFlag = (id) => {
  let data = axios
    .get(`admin/flags${id}`)
    .then((res) => {
      let flags = res.data;
      return flags;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addFlag = (data) => {
  let result = axios
    .post(`admin/flags`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flag created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Flag creation failed' };
    });
  return result;
};

export const deleteFlag = (id) => {
  let result = axios
    .delete(`admin/flags/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flags deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editFlag = (id, data) => {
  let result = axios
    .patch(`admin/flags/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flag updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Language update failed' };
    });
  return result;
};

export const flagEmployee = (data) => {
  let result = axios
    .post(`admin/employee-flag`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flag created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message,
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Flag creation failed' };
    });
  return result;
};
export const editEmployeeFlag = (data, id) => {
  let result = axios
    .patch(`admin/employee-flag/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flag edited  successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Flag creation failed' };
    });
  return result;
};

export const resolveEmployeeFlag = (data, id) => {
  let result = axios
    .patch(`admin/employee-flag/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Flag edited  successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Flag creation failed' };
    });
  return result;
};

// Templates
export const getTemplates = (query) => {
  let data = axios
    .get(`admin/templates${query}`)
    .then((res) => {
      let templates = res.data;
      return templates;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getTemplate = (id) => {
  let data = axios
    .get(`admin/templates?${id}`)
    .then((res) => {
      let templates = res.data;
      return templates;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addTemplate = (data) => {
  let result = axios
    .post(`admin/templates`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Template creation failed' };
    });
  return result;
};

export const editTemplate = (id, data) => {
  let result = axios
    .patch(`admin/templates/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Language update failed' };
    });
  return result;
};

export const deleteTemplate = (id) => {
  let result = axios
    .delete(`admin/templates/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

// Template Questions
export const getTemplateQuestions = (query) => {
  let data = axios
    .get(`admin/template-questions?${query}`)
    .then((res) => {
      let templates = res.data;
      return templates;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

// export const getTemplate = (id) => {
//   let data = axios
//     .get(`admin/templates?${id}`)
//     .then((res) => {
//       let templates = res.data;
//       return templates;
//     })
//     .catch((err) => {
//       //   console.log("error occured");
//       return { data: [] };
//     });
//   return data;
// };

export const addTemplateQuestion = (data) => {
  let result = axios
    .post(`admin/template-questions`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template Question created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Template creation failed' };
    });
  return result;
};

export const editTemplateQuestion = (id, data) => {
  let result = axios
    .patch(`admin/template-questions/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template Question updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Language update failed' };
    });
  return result;
};

export const deleteTemplateQuestion = (id) => {
  let result = axios
    .delete(`admin/template-questions/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Template deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

// Skill CATEGORIES .get(`admin/cities?all=1&state_id=${state_id}`)
export const getJobTitleSearchables = () => {
  let data = axios
    .get(`admin/jobTitles?all=1`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

//  we can use it to access job titles in employee and employer
export const getJobTitlesForPopulates = (role = null) => {
  let param = '';
  if (role !== null) if (role === 'employers') param = '&is_open=1';
  let data = cachedAxios({
    url: `jobTitlesall?all=1&active_status=1${param}`,
    method: 'get',
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getSkillCategories = (query) => {
  let data = axios
    .get(`admin/skillCategories${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      //   console.log("error occured");
      return { data: [] };
    });
  return data;
};

export const addSkillCategory = (data) => {
  let result = axios
    .post(`admin/skillCategories`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill category created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Skill category creation failed' };
    });
  return result;
};

export const deleteSkillCategory = (id) => {
  let result = axios
    .delete(`admin/skillCategories/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill category deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editSkillCategory = (id, data) => {
  let result = axios
    .patch(`admin/skillCategories/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill category updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Skill category update failed',
      };
    });
  return result;
};

export const viewSkillCategory = (id) => {
  return { status: 'success', data: {} };
};

export const restoreSkillCategory = (id) => {
  return { status: 'success' };
};

export const getLeaveTypes = (query) => {
  let data = axios
    .get(`admin/leaveTypes${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addLeaveType = (data) => {
  let result = axios
    .post(`admin/leaveTypes`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Leave types created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Leave type creation failed' };
    });
  return result;
};

export const deleteLeaveType = (id) => {
  let result = axios
    .delete(`admin/leaveTypes/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Leave Type deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editLeaveType = (id, data) => {
  let result = axios
    .patch(`admin/leaveTypes/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Leave Type updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Leave Type update failed',
      };
    });
  return result;
};

export const viewLeaveType = (id) => {
  return { status: 'success', data: {} };
};

export const restoreLeaveType = (id) => {
  return { status: 'success' };
};

// What's New Type/Category
export const getWhatsNewTypeData = (query) => {
  let data = axios
    .get(`admin/announcementCategories${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getWhatsNewTypeForPopulates = () => {
  let data = cachedAxios({
    url: `admin/announcementCategories?all=1`,
    method: 'get',
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addWhatsNewType = (data) => {
  let result = axios
    .post(`admin/announcementCategories`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's New type added successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: "What's new type addition failed" };
    });
  return result;
};

export const deleteWhatsNewType = (id) => {
  let result = axios
    .delete(`admin/announcementCategories/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's new type deleted successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editWhatsNewType = (id, data) => {
  let result = axios
    .patch(`admin/announcementCategories/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's New type updated successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: "What's new type update failed",
      };
    });
  return result;
};

// What's New / Announcements
export const getWhatsNewData = (query) => {
  let data = axios
    .get(`admin/announcements${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const addWhatsNew = (data) => {
  let result = axios
    .post(`admin/announcements`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's New data added successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: "What's new addition failed" };
    });
  return result;
};

export const deleteWhatsNew = (id) => {
  let result = axios
    .delete(`admin/announcements/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's new item deleted successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const editWhatsNew = (id, data) => {
  let result = axios
    .patch(`admin/announcements/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: "What's New data updated successfully",
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: "What's new data update failed",
      };
    });
  return result;
};

export const getSkills = (query) => {
  let data = axios
    .get(`admin/skills${query}`)
    .then((res) => {
      let skills = res.data.data;
      return skills;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getSkillsForPopulates = () => {
  let data = axios
    .get(`skillsall?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const addSkill = (data) => {
  let result = axios
    .post(`admin/skills`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Skill creation failed' };
    });
  return result;
};

export const editSkill = (id, data) => {
  let result = axios
    .patch(`admin/skills/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Skill update failed' };
    });
  return result;
};

export const deleteSkill = (id) => {
  let result = axios
    .delete(`admin/skills/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const restoreSkill = (id) => {
  let result = axios
    .delete(`admin/skills/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skill deleted successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while deleting',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while deleting',
      };
    });
  return result;
};

export const getSkillCategoriesForPopulates = (jobTitle) => {
  let extraParam = jobTitle ? `&job_title_id=${jobTitle}` : '';

  let data = axios
    .get(`skillCategoriesall?active_status=1${extraParam}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const getSuggestedSkills = (query) => {
  let data = axios
    .get(`job_skills/?job_title_id=${query}`)
    .then((res) => {
      let data = res.data;
      return data;
    })
    .catch((err) => {
      //
      return { data: [] };
    });

  return data;
};

export const getIssues = (query = '') => {
  let qs = queryString.parse(query);
  let data = axios
    .get(`admin/issues-list?${queryString.stringify(qs)}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return [];
    });
  return data;
};

export const editIssue = (id, data) => {
  let result = axios
    .patch(`admin/update-issue/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Issue updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: result?.data?.message ?? 'Kindly fix the errors.',
        };
    })
    .catch((err) => {
      console.log('error occured', err);
      return { status: 'error', errors: {}, message: 'Issue update failed' };
    });
  return result;
};
