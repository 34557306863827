import React from "react";
import { Typography, Hidden, withStyles } from "@material-ui/core";
import WhiteLogo from "../../assets/images/logowhite.png";
import { ReactComponent as EmployeeAvatar } from "../../assets/employeeAvatar.svg";
import { ReactComponent as EmployerAvatar } from "../../assets/employerAvatar.svg";
import { Link, withRouter } from "react-router-dom";
import RoundedButton from "../RoundedButton";
import GraphicsTop from "../../assets/images/graphicsTop.png";
import GraphicsBottom from "../../assets/images/graphicsBottom.png";

const Homepage = (props) => {
  let { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.blueBackground}></div>
        <div className={classes.containerInner}>
          {/* Graphics Gimmicks */}
          <img alt="" className={classes.graphicsTop} src={GraphicsTop} />
          <img alt="" className={classes.graphicsBottom} src={GraphicsBottom} />
          <div className={classes.top}>
            <img alt="" src={WhiteLogo} />
            <Typography>
              <span>
                Hire from a pool of 100s of professionals or work for
                organizations around the world!
              </span>
              <br />
              <Hidden smDown={true}>
                Select your login area to access your dashboard
              </Hidden>
            </Typography>
          </div>
          <div className={classes.cardsContainer}>
            {/* Mobile title text start */}
            {/* <div className={classes.mobileText}>
              <Typography>Welcome!</Typography>
              <Typography>Select you sign up area and access your dashboard</Typography>
            </div> */}
            {/* Mobile title text end */}
            <div className={classes.cards}>
              {/* Desktop Cards Start */}
              <div className={classes.card}>
                <EmployerAvatar height={"9rem"} width={"9rem"} />
                <Typography>I want to hire</Typography>
                <div className={classes.cardButton}>
                  <RoundedButton
                    variant="outlined"
                    fullWidth={true}
                    handleSubmit={() =>
                      props.history.push("/register?role=employer")
                    }
                  >
                    <span style={{ fontWeight: 500 }}>SIGN UP</span>
                  </RoundedButton>
                </div>
                <Typography className={classes.greyText}>
                  Already have an account?{" "}
                  <Link to="/login?role=employer" className={classes.linkText}>
                    Login
                  </Link>
                </Typography>
              </div>

              <div className={classes.card}>
                <EmployeeAvatar height={"9rem"} width={"9rem"} />
                <Typography>I want to work</Typography>
                <div className={classes.cardButton}>
                  <RoundedButton
                    variant="outlined"
                    fullWidth={true}
                    handleSubmit={() =>
                      props.history.push("/register?role=employee")
                    }
                  >
                    <span style={{ fontWeight: 500 }}>SIGN UP</span>
                  </RoundedButton>
                </div>
                <Typography className={classes.greyText}>
                  Already have an account?{" "}
                  <Link to="/login?role=employee" className={classes.linkText}>
                    Login
                  </Link>
                </Typography>
              </div>
              {/* Desktop Cards End */}

              {/* {Mobile Cards Start} */}
              <div
                className={classes.mobileCard}
                style={{ marginBottom: "2.75rem" }}
              >
                {/* Left side start */}
                <div className={classes.mobileCardImageLeft}>
                  <EmployerAvatar
                    height={"6rem"}
                    width={"4.5rem"}
                    style={{ marginTop: "1rem", marginRight: "0.25rem" }}
                  />
                </div>
                {/* Left side end */}
                {/* Right side start */}
                <div className={classes.mobileCardTextContent}>
                  <Typography>I Want To Hire</Typography>
                  <Typography
                    onClick={() =>
                      props.history.push("/register?role=employer")
                    }
                  >
                    SIGN UP
                  </Typography>
                  <Typography className={classes.greyText}>
                    Already have an account?{" "}
                    <Link
                      to="/login?role=employer"
                      className={classes.linkText}
                    >
                      Login
                    </Link>
                  </Typography>
                </div>
                {/* Right side end */}
              </div>

              <div className={classes.mobileCard}>
                {/* Right side start */}
                <div className={classes.mobileCardTextContent}>
                  <Typography>I Want To Work</Typography>
                  <Typography
                    onClick={() =>
                      props.history.push("/register?role=employee")
                    }
                  >
                    SIGN UP
                  </Typography>
                  <Typography className={classes.greyText}>
                    Already have an account?{" "}
                    <Link
                      to="/login?role=employee"
                      className={classes.linkText}
                    >
                      Login
                    </Link>
                  </Typography>
                </div>
                {/* Right side end */}

                {/* Left side start */}
                <div className={classes.mobileCardImageRight}>
                  <EmployeeAvatar
                    height={"6rem"}
                    width={"4.5rem"}
                    style={{ marginTop: "1rem", marginLeft: "1rem" }}
                  />
                </div>
                {/* Left side end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const materialStyles = (theme) => ({
  main: {
    backgroundColor: "#F5F5F5",
    position: "relative",
  },
  blueBackground: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    height: "50vh",
    width: "100%",
    position: "absolute",
    top: 0,
    "@media (max-height: 500px)": {
      height: "50vh",
    },
  },
  container: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "center",
    maxWidth: "53.8rem",
    margin: "0 auto",
    paddingTop: "2rem",
    zIndex: 1,
    "& > *": { zIndex: 2 },
    [theme.breakpoints.down("xs")]: {
      margin: "0 0",
    },
  },
  containerInner: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  // top
  top: {
    textAlign: "center",
    zIndex: 10,
    marginBottom: "3rem",
    marginTop: "2rem",
    "& > :first-child": {
      width: "11rem",
      objectFit: "cover",
      marginBottom: "1rem",
    },
    "& > :nth-child(2)": {
      color: "#fff",
      fontSize: "1.3rem",
    },
  },
  cardsContainer: {
    zIndex: 10,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "0 2rem",
      alignItems: "center",
      overflow: "hidden",
      paddingTop: "2.75rem",
      backgroundColor: "#fafafa",
      borderRadius: "3.5rem 3.5rem 0 0",
    },
  },
  mobileText: {
    display: "none",
    zIndex: 10,
    marginBottom: "1.625rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    // welcome text
    "& > :first-child": {
      textAlign: "center",
      color: theme.palette.primary.main,
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    // text below welcome
    "& > :nth-child(2)": {
      color: "#8C8C8C",
      fontSize: "1.3rem",
      textAlign: "center",
    },
  },
  mobileCardImageLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FEF1F1",
    width: "7.25rem",
    borderRadius: "0 10rem 0 0",
    position: "relative",
    "& img": {
      width: "3.5rem",
      position: "absolute",
      left: "1.65rem",
    },
  },
  mobileCardImageRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E6F0F7",
    width: "7.25rem",
    borderRadius: "10rem 0 0 0 ",
    position: "relative",
    "& img": {
      width: "3.5rem",
      position: "absolute",
      right: "1.65rem",
    },
  },
  mobileCard: {
    display: "flex",
    borderRadius: "0.625rem",
    boxShadow: "0px 0px 8px 2px #ccc",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: { display: "none" },
  },
  mobileCardTextContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    padding: "1.625rem 0",
    // title text
    "& > :first-child": {
      fontSize: "1.6rem",
      fontWeight: 500,
      marginBottom: "1rem",
    },
    // button
    "& > :nth-child(2)": {
      backgroundColor: theme.palette.primary.main,
      maxWidth: "7.5rem",
      color: "#fff",
      fontWeight: 500,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.625rem 1.5rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
    },
  },
  cards: {
    display: "flex",
    zIndex: 10,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    "& > :first-child": { marginRight: "3rem" },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& > *": {
        marginRight: 0,
        marginBottom: "1rem",
      },
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "24rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 4px 10px 3px",
    borderRadius: "0.625rem",
    padding: "2.5rem",
    marginBottom: "5rem",
    boxSizing: "border-box",
    "& > :first-child": { marginBottom: "1rem" },
    // avater images
    "& > img": {
      width: "7rem",
      objectFit: "cover",
      marginBottom: "2rem",
      marginTop: "1rem",
    },
    // headline
    "& > :nth-child(2)": {
      fontSize: "1.75rem",
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: "1rem",
    },
    // button
    "& > :nth-child(3)": {
      width: "16rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "none",
    },
  },
  cardButton: {
    marginBottom: "0.75rem",
  },
  // misc
  linkText: {
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  greyText: { color: "#8C8C8C" },
  "@keyframes rotate": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(-45deg)",
    },
  },
  graphicsTop: {
    position: "absolute",
    width: "20rem",
    right: 0,
    top: 0,
    [theme.breakpoints.down("xs")]: {
      width: "10rem",
    },
  },
  graphicsBottom: {
    position: "absolute",
    width: "15rem",
    left: 0,
    bottom: 0,
  },
});
export default withRouter(withStyles(materialStyles)(Homepage));
