import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// TODO: Proper Dispatch (Employer_info) on Payment Card add
// TODO: the app should not logout on a failed connection
// TODO: Fix Page scrolling issues
// TODO: Handle APIs logouts
// TODO: Keyboard Tab Key orders, tab switches for inputs: low priority
// TODO: Handle Keyboard enter submit
// TODO: First input autofocus
//TODO: Scroll trigger move the admin sidebar up after a certain amount of scroll is achieved
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { SnackbarProvider, useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withRouter, Redirect } from 'react-router-dom';
import FullscreenLoader from './components/FullscreenLoader';
// redux stuff
import { Provider, connect } from 'react-redux';
import store from './redux/configureStore';
import { validateToken } from './apis/user';
import { updateAuthorizationToken, setClearRedux } from './utils/axios';
import { pusherPK } from '../src/utils/keys';
import { setUser, clearUser, setEmployeeInfo, setEmployerInfo } from './redux/actionCreators';
import TagManager from 'react-gtm-module';
import Pusher from 'pusher-js';
import CrashPage from './components/CrashPage';
// import ErrorsPage from "./components/ErrorsPage";
import 'react-phone-input-2/lib/material.css';
import LoginForm from './components/LoginForm/';
import RegisterForm from './components/RegisterForm/';
import Homepage from './components/HomePage/';
import CONSTANTS from './utils/constants';
import { Helmet } from 'react-helmet';
import { usePageView, useUsermaven } from './useUsermaven';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// Dynamic Imports
const Admin = React.lazy(() => import('./sections/admin/'));
const Employer = React.lazy(() => import('./sections/employer/'));
const Employee = React.lazy(() => import('./sections/employee/'));

// Custom Functions Added to String Prototype
// eslint-disable-next-line no-extend-native
String.prototype.lpad = function (padString, length) {
  var str = this;
  while (str.length < length) str = padString + str;
  return str;
};
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
// eslint-disable-next-line no-extend-native
String.prototype.trimTimezeone = function () {
  let symbol = this.substring(0, 1);
  let data = this.substring(1, this.length);
  return `${symbol}${parseFloat(data)}`;
};

const theme = createTheme({
  overrides: {
    MuiPaginationItem: {
      page: { backgroundColor: '#fff' },
    },
    MuiAlert: {
      root: {
        padding: '0',
        boxShadow: '0px 15px 33px -19px rgba(0,0,0,0.75)',
        backgroundColor: '#fff',
        borderRadius: '10px',
      },
      standardSuccess: {
        backgroundColor: '#fff',
      },
      standardError: {
        backgroundColor: '#fff',
      },
      icon: {
        padding: '15px',
        fontSize: '33px',
        marginRight: '2rem',
      },
      message: {
        padding: '0',
        lineHeight: '3.7rem',
      },
      action: {
        marginRight: '4px',
      },
    },
    '.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'TTNorms',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#006ab3',
      light: '#2680be',
      success: '#71c152',
      dark: '#005a98',
      contrastText: '#fff',
      error: '#E15833',
    },
    secondary: {
      main: '#FC443E',
      dark: '#EC3A34',
      light: '#FB5954',
      contrastText: '#fff',
    },
  },
});

function SnackbarCloseButton({ key }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton size='small' color='#fff' onClick={() => closeSnackbar(key)}>
      <CancelIcon htmlColor='#fff' />
    </IconButton>
  );
}

const gtm_args = {
  gtmId: 'GTM-WC3PFVW',
};
if (process.env.REACT_APP_ENV === 'production') TagManager.initialize(gtm_args);

let usermavenOpts = {
  key: 'UMTGUugd31',
  tracking_host: 'https://events.usermaven.com',
};

function UserMavenHook() {
  const { usermaven } = useUsermaven(usermavenOpts);
  usePageView(usermaven);

  if (
    localStorage.getItem('user_role') &&
    localStorage.getItem('user_role') === 'employer' &&
    process.env.REACT_APP_ENV === 'production'
  ) {
    const { id, email, created_at, first_name, last_name } = JSON.parse(localStorage.getItem('user'));

    usermaven.id({
      // Required attributes
      id, // Unique ID for the user in database.
      email, // Email address for the user.
      created_at, // DateTime string in your system that represents when the user first signed up.

      // Recommended attributes
      // First name and last name are shown on user pages.
      first_name,
      last_name,

      // Optional attributes (you can name attributes what you wish)
      custom: {
        role: localStorage.getItem('user_role'),
      },
    });
  }

  return null;
}

class AppUI extends React.Component {
  constructor(props) {
    super(props);
    this.tawkMessengerRef = React.createRef();
    this.state = {
      isLoading: true,
      hasError: false,
      isSubmitLoading: false,
      role: '',
      isUnauthorized: false,
    };

    this.pusher = new Pusher(pusherPK, {
      cluster: 'ap2',
      authEndpoint: CONSTANTS.pusherAuthURL,
    });
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    setClearRedux(this.props.clearUser);
    // check if token exists in the localstorage
    let token = localStorage.getItem('user_verif');
    let user = localStorage.getItem('user');
    let role = localStorage.getItem('user_role');

    this.setState({ role: role });
    if (token !== null && user !== null) {
      // valdiate token
      validateToken(role, token).then((result) => {
        // set employee info here
        // redirect to login if some issue
        let userJson = JSON.parse(user);

        if (result.response && result.response.status === 401) {
          this.props.setUser({
            user: userJson ?? null,
            isVerified: false,
            isVerifying: false,
            role: role,
          });
          return;
        }

        if (result.data && result.data.status === 'success') {
          if (result.data.data !== undefined) userJson = { ...userJson, ...result.data.data.user };

          // update auth token
          updateAuthorizationToken(token);
          // wallet info etc
          if (role === 'employee') this.props.setEmployeeInfo(result.data.data);
          else if (role === 'employer') this.props.setEmployerInfo(result.data.data);

          // store user in redux store
          this.props.setUser({
            user: userJson ? userJson : null,
            isVerified: true,
            isVerifying: false,
            role: role,
            subRole: result.data?.staff_role,
          });

          // redirect
          let path = window.location.pathname;
          // eslint-disable-next-line
          let re = `\/${role}\/`;
          let results = path.match(re);
          if (results === null) this.props.history.push(`/${role}/dashboard`);
        } else if (result.data && result.data?.status === 'failure') {
          localStorage.removeItem('user');
          localStorage.removeItem('user_role');
          localStorage.removeItem('user_verif');
          localStorage.removeItem('skip_2fa');
          this.props.setUser({
            user: null,
            role: null,
            isVerified: false,
            isVerifying: false,
          });
        } else {
          // TODO: allow app if the user is offline
          this.props.setUser({
            user: userJson ? userJson : null,
            isVerified: true,
            isVerifying: false,
            role: role,
          });
          this.setState({ hasError: true });
        }
      });
    } else {
      // if the token is invalid
      localStorage.removeItem('user');
      localStorage.removeItem('user_role');
      localStorage.removeItem('user_verif');
      localStorage.removeItem('skip_2fa');
      this.props.setUser({
        user: null,
        role: null,
        isVerified: false,
        isVerifying: false,
      });
    }
  };

  // renderZendeskScript = () => {
  //   // {/* <!--Start of Zendesk Script--> */ }
  //   if (
  //     localStorage.getItem('user_role') &&
  //     localStorage.getItem('user_role') !== 'admin' &&
  //     process.env.REACT_APP_ENV === 'production'
  //   )
  //     return (
  //       <script
  //         id='ze-snippet'
  //         src='https://static.zdassets.com/ekr/snippet.js?key=12441fb2-49db-46a3-91ef-4c59faf609de'
  //       ></script>
  //     );
  //   else return;
  //   // {/* <!--End of Zendesk Script--> */ }
  // };

  renderHotjarScript = () => {
    // <!-- Hotjar Tracking Code for https://qa-web.worktually.com/ -->

    let data = (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3071938, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    return <script type='application/javascript' dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />;
  };

  handleMinimize = () => {
    this.tawkMessengerRef.current.minimize();
  };

  render() {
    if (this.state.hasError === true) return <CrashPage message={'Oh snap.'} />;
    return (
      <>
        <UserMavenHook />
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Helmet>
              <title>{CONSTANTS.Sitename}</title>
              {/* this.renderZendeskScript() */}

              {localStorage.getItem('user_role') &&
                localStorage.getItem('user_role') !== 'admin' &&
                process.env.REACT_APP_ENV === 'production' &&
                this.renderHotjarScript()}
            </Helmet>
            <noscript>
              {/* eslint-disable-next-line */}
              <iframe
                title='Daily Tags'
                src='https://www.googletagmanager.com/ns.html?id=GTM-WC3PFVW'
                height='0'
                width='0'
                // eslint-disable-next-line react/style-prop-object
                style='display:none;visibility:hidden'
              ></iframe>
            </noscript>
            <SnackbarProvider maxSnack={3} action={(key) => <SnackbarCloseButton key={key} />} hideIconVariant>
              {this.props.isVerifying === false ? (
                <div>
                  {this.props.isVerified ? (
                    <Switch>
                      <Route path='/admin'>
                        <Suspense fallback={<FullscreenLoader />}>
                          <Admin pusher={this.pusher} />
                        </Suspense>
                      </Route>
                      <Route path='/employer'>
                        <Suspense fallback={<FullscreenLoader />}>
                          <Employer pusher={this.pusher} />
                        </Suspense>
                      </Route>
                      <Route path='/employee'>
                        <Suspense fallback={<FullscreenLoader />}>
                          <Employee pusher={this.pusher} />
                        </Suspense>
                      </Route>
                      <Route exact path='/'>
                        <Redirect to={this.props.role} />
                      </Route>
                    </Switch>
                  ) : (
                    <Switch>
                      <Route exact path='/'>
                        <Homepage />
                      </Route>
                      <Route path='/login'>
                        <LoginForm fullScreen key={this.props.location.key} />
                      </Route>
                      <Route path='/register'>
                        <RegisterForm fullScreen key={this.props.location.key} />
                      </Route>
                      <Route path='/admin/login'>
                        <LoginForm fullScreen />
                      </Route>
                      <Route path='/admin'>
                        <Redirect to='/admin/login?role=admin' />
                      </Route>
                      <Route path='/*'>
                        <Redirect to='/' />
                      </Route>
                    </Switch>
                  )}
                </div>
              ) : (
                <FullscreenLoader />
              )}
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.user;
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    clearUser: () => dispatch(clearUser()),
    setEmployeeInfo: (employeeInfo) => dispatch(setEmployeeInfo(employeeInfo)),
    setEmployerInfo: (employerInfo) => dispatch(setEmployerInfo(employerInfo)),
  };
};

const AppUIWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(AppUI));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <Provider store={store}>
        <CrashPage>
          <Router>
            <Suspense fallback={<FullscreenLoader />}>
              <AppUIWrapper />
              {localStorage.getItem('user_role') &&
                localStorage.getItem('user_role') !== 'admin' &&
                process.env.REACT_APP_ENV === 'production' && (
                  <TawkMessengerReact
                    propertyId='6144670925797d7a89ff7626'
                    widgetId='1ffpl33ac'
                    ref={this.tawkMessengerRef}
                  />
                )}
            </Suspense>
          </Router>
        </CrashPage>
      </Provider>
    );
  };
}

export default App;
