import React from "react";
import { ButtonBase, Typography } from "@material-ui/core";
import BlueArrow from "../../assets/icons/blueArrowForward.png";
import clsx from "clsx";
import { withStyles } from "@material-ui/core";

const RoleSwitch = (props) => {
  let { classes, role, onChange } = props;

  return (
    <div className={classes.container} onClick={onChange}>
      <div
        className={clsx(role === "employer" && classes.selected, classes.item)}
      >
        <Typography>Employer</Typography>
      </div>
      <ButtonBase className={classes.switchOuter}>
        <div className={classes.switchInner}>
          <img
            alt=""
            src={BlueArrow}
            className={clsx(
              classes.icon,
              role === "employee" && classes.iconBack
            )}
          />
        </div>
      </ButtonBase>
      <div
        className={clsx(classes.item, role === "employee" && classes.selected)}
      >
        <Typography>Employee</Typography>
      </div>
    </div>
  );
};

const materialStyles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#D9F5FF",
    borderRadius: 50,
    overflow: "hidden",
    height: "3rem",
    position: "relative",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    height: "100%",
    transition: "background 0.25s ease-in",
    cursor: "pointer",
    "& > p": {
      fontSize: "1rem",
      fontWeight: 500,
      color: "#88B9CB",
      transition: "color 0.25s ease-in",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    "& > p": {
      color: "#fff",
    },
  },
  // switch button
  switchOuter: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    height: "3rem",
    width: "3rem",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    transition: "background .25s ease-in",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  switchInner: {
    height: "1.5rem",
    width: "1.5rem",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  icon: {
    width: "0.5rem",
  },
  iconBack: {
    transform: "rotate(-180deg)",
  },
});

export default withStyles(materialStyles)(RoleSwitch);
