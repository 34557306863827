import * as ActionTypes from "../actionTypes";
import moment from "moment";
// TODO: we might also have to add checkout time

// CheckinStatus is 1 for checked in 2 for checkout
export const employeeInfo = (
  state = {
    balance: 0, check_in_time: moment(),
    attendance_id: null, applied_job_id: null,
    isCheckedin: false, isLoading: true,
    educations: [], languages: [], document: []
  },
  action
) => {
  switch (action.type) {
    // TODO: Send time from component
    case ActionTypes.TOOGLE_CHECKIN:
      if (state.isCheckedin === null || state.isCheckedin === false) {
        return {
          ...state, isCheckedin: true, check_in_time: moment().format('YYYY-MM-DD HH:mm:ss'),
          attendance_id: action.payload?.attendance_id
        };
      }
      else {
        return { ...state, isCheckedin: false, attendance_id: null };
      }
    case ActionTypes.SET_BALANCE:
      return { ...state, ...action.payload, isLoading: false }
    case ActionTypes.SET_EMPLOYEE_INFO:
      // Check that if the user is checked in or not.
      action.payload.isCheckedin = action.payload.status === 1 ? true : false
      return { ...state, ...action.payload, isLoading: false }
    case ActionTypes.CLEAR_EMPLOYEE:
      return {
        balance: 0, check_in_time: moment(),
        attendance_id: null, applied_job_id: null, educations: [], languages: [], document: [],
        isCheckedin: false, isLoading: true
      }
    default:
      return state;
  }
};
