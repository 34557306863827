import React, { useState, useRef } from "react";
import clsx from "clsx";
import {
  TextField,
  Typography,
  InputAdornment,
  LinearProgress,
  IconButton,
  Fade,
  makeStyles
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Popper from "@material-ui/core/Popper";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/svg/checkMarkPasswordStrength.svg";

function PasswordWithStrength(props) {
  let componentStyles = makeStyles((theme) => ({
    container: { width: "100%", position: "relative" },
    popper: { zIndex: 9999, top: "0.85rem !important" },
    strenghtPopup: {
      width: 260,
      backgroundColor: "#fff",
      border: "1px solid #DEE2E6", // #4141411a
      padding: "0.75rem 1rem 2rem 1rem",
      boxShadow: "10px 7px 10px #00000005"
    },
    arrow: {
      height: "1rem",
      width: "1rem",
      backgroundColor: "#fff",
      border: "1px solid #DEE2E6", // #4141411a
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translate(-50%, -50%) rotate(45deg)",
      borderRight: 0,
      borderBottom: 0,
      boxSizing: "border-box"
    },
    stength_item: {
      display: "flex",
      alignItems: "center",
      "& > :first-child": {
        marginRight: "0.625rem"
      }
    },
    progressbar_root: {
      height: "0.5rem",
    },
    progressbar_background_color: {
      backgroundColor: "#ADB5BD",
    },
    red_bar: { backgroundColor: "#FC2F29" },
    orange_bar: { backgroundColor: "#FF8000" },
    yellow_bar: { backgroundColor: "#FFCC00" },
    green_bar: { backgroundColor: "#47CB5D" },
    icon: { fill: "#8C8C8C" },
    iconChecked: { fill: "#47CB5D" },
    section_1: {
      marginBottom: "0.75rem",
    },
    section_2: {
      "& > *": {
        marginBottom: "0.5rem",
      },
    },
    section_3: {
      marginTop: "0.5rem",
      "& > .heading": {
        marginBottom: "0.25rem",
      },
    },
    section_4: {
      marginTop: "0.5rem",
    },
    input: {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    }
  }));

  const rootRef = useRef();
  const [showPassword, toggleShowPassword] = useState(false);
  const [showStrength, setShowStrength] = useState(false);

  let ver_eight_character = true;
  let ver_small_and_large = false;
  let ver_number = false;

  const regex_eight_characters = new RegExp(".{8}");
  const regex_small_and_large = new RegExp("(?=.*[a-z])(?=.*[A-Z])");
  const regex_number = new RegExp("[0-9]");

  ver_eight_character = regex_eight_characters.test(props.value);
  ver_small_and_large = regex_small_and_large.test(props.value);
  ver_number = regex_number.test(props.value);

  let progress_value = (ver_eight_character ? 1 : 0) + (ver_small_and_large ? 1 : 0) + (ver_number ? 1 : 0) + (props.value ? 1 : 0);

  const classes = componentStyles();
  return (
    <div className={classes.container} ref={rootRef}>
      <TextField
        required={props.required}
        variant={props.variant}
        label={props.label}
        name={props.name}
        tabIndex={props.tabIndex}
        type={showPassword ? "text" : "password"}
        onChange={props.onChange}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
          setShowStrength(false);
        }}
        onFocus={(e) => {
          setShowStrength(true);
          if (props.onFocus) props.onFocus(e);
        }}
        fullWidth={props.fullWidth}
        value={props.value}
        error={props.error ? true : false}
        helperText={props.error && props.helperText}
        inputProps={{ className: classes.input }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" variant="filled" tabIndex={-1}>
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => toggleShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: false
          }
        }}
        popperOptions={{ position: "bottom" }}
        open={showStrength}
        anchorEl={rootRef.current}
        placement={"bottom"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.strenghtPopup}>
              <div className={classes.arrow}></div>
              <Typography className={classes.section_1}>
                Your password must have:
              </Typography>
              {/* Sect 2: Strength Attributes Start */}
              <div className={classes.section_2}>
                <div className={classes.stength_item}>
                  <CheckMarkIcon
                    className={clsx(ver_eight_character ? classes.iconChecked : classes.icon)}
                    width="1.25rem"
                    height="1.25rem"
                  />
                  <Typography>8 or more characters</Typography>
                </div>
                <div className={classes.stength_item}>
                  <CheckMarkIcon
                    className={clsx(ver_small_and_large ? classes.iconChecked : classes.icon)}
                    width="1.25rem"
                    height="1.25rem"
                  />
                  <Typography>Upper and Lowercase letters</Typography>
                </div>
                <div className={classes.stength_item}>
                  <CheckMarkIcon
                    className={clsx(ver_number ? classes.iconChecked : classes.icon)}
                    width="1.25rem"
                    height="1.25rem"
                  />
                  <Typography>Atlease one number</Typography>
                </div>
              </div>
              {/* Sect 2: Strength Attributes Start */}

              {/* Sect 3: Strength Attributes Start */}
              <div className={classes.section_3}>
                <Typography className="heading">Strength</Typography>
                <LinearProgress
                  classes={{
                    root: classes.progressbar_root,
                    colorPrimary: classes.progressbar_background_color,
                    bar: clsx(
                      (progress_value === 1 || progress_value === 0) && classes.red_bar,
                      progress_value === 2 && classes.orange_bar,
                      progress_value === 3 && classes.yellow_bar,
                      progress_value === 4 && classes.green_bar
                    )
                  }}
                  value={(progress_value / 4) * 100}
                  variant="determinate"
                />
              </div>
              {/* Sect 3: Strength Attributes End */}

              {/* Sect 4: Discription start */}
              <Typography className={classes.section_4}>
                Avoid using a password that you use with other websites or that
                might be easy for someone else to guess.
              </Typography>
              {/* Sect 4: Discription end */}
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default PasswordWithStrength;