import { axios } from '../utils/axios';
import { getErrors } from '../utils/validations';
import queryString from 'query-string';

export const uploadDocument = (data) => {
  let result = axios
    .post('admin/employeeDocument', data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          data: res.data.data,
          status: 'success',
          message: 'Documents uploaded successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Documents upload failed! ',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Documents upload failed! ' };
    });
  return result;
};

export const reconcileEmployeeWallet = (id) => {
  let data = axios
    .get(`admin/reconcile/wallet/employee/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const reconcileEmployerWallet = (id) => {
  let data = axios
    .get(`admin/reconcile/wallet/employer/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getPermissionsAll = (id) => {
  let data = axios
    .get(`admin/permissions?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getAdminRolesAll = (id) => {
  let data = axios
    .get(`admin/roles?all=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getAdminRoles = (query = '') => {
  let qs = queryString.parse(query);

  let data = axios
    .get(`admin/roles?${queryString.stringify(qs)}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const addPermissionsToRole = (data) => {
  let result = axios
    .post(`admin/assign_permission_to_role`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Permissions updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const addAdminRole = (data) => {
  let result = axios
    .post(`admin/roles`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Role added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const editAdminRole = (data, id) => {
  let result = axios
    .patch(`admin/roles/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'User updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'User update failed!' };
    });
  return result;
};

export const deleteAdminRole = (id) => {
  let result = axios
    .delete(`admin/roles/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'User removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const restoreAdminUser = (id) => {
  let result = axios
    .delete(`admin/users/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'User restored successfully!',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: 'Issue occured while restoring!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring!',
      };
    });
  return result;
};

export const getAdminUser = (search) => {
  let data = axios
    .get(`admin/users${search}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const addAdminUser = (data) => {
  let result = axios
    .post(`admin/users`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'User added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const editAdminUser = (data, id) => {
  let result = axios
    .patch(`admin/users/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'User updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'User update failed!' };
    });
  return result;
};

export const deleteAdminUser = (id) => {
  let result = axios
    .delete(`admin/users/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'User removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const approveRefund = (refund_id) => {
  let data = axios
    .get(`admin/refund/${refund_id}/1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const cancelRefund = (refund_id) => {
  let data = axios
    .get(`admin/refund/${refund_id}/2`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getRefundsList = (query = '') => {
  let data = axios
    .get(`admin/refund${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getEmployeeByEmployer = (employer_id, query = '') => {
  let data = axios
    .get(`admin/employee-by-employer/${employer_id}${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const updateSettingsEmployer = (id, data) => {
  let result = axios
    .post(`admin/employers/updateSettings/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Settings updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.errors ? res.errors : null,
          message: res.message,
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Settings updation failed!' };
    });
  return result;
};

export const updateSettingsEmployee = (data) => {
  let result = axios
    .post(`admin/update-job-settings`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Settings updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.errors ? res.errors : null,
          message: res.data?.message ?? 'Please check your internet connection or try again later.',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Settings update failed!' };
    });
  return result;
};

// TODO Fix URL
export const updateShiftTimingsEmployee = (data) => {
  let result = axios
    .post(`admin/update-shift-timing`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Shift updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? res.data.errors : null,
          message: res.data.message,
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Shifts update failed!' };
    });
  return result;
};

export const getOffers = (query = '') => {
  let data = axios
    .get(`admin/jobOffers${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getInterviews = (query = '') => {
  let data = axios
    .get(`admin/interviews${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const restoreJobPost = (id) => {
  let result = axios
    .post(`admin/employerJobs/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job restored successfully!',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: result?.message ?? 'Issue occured while restoring!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring!',
      };
    });
  return result;
};

export const updateEmployementStatus = (id, status = 0) => {
  let result = axios
    .patch(`admin/ex-employee/${id}`, { status })
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Success',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Unable to process request at this moment!',
      };
    });

  return result;
};

export const getRefunds = (query = '') => {
  let data = axios
    .get(`admin/refund${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

// finance module start
export const getEmployerWallets = (query = '') => {
  let data = axios
    .get(`admin/employersWallets${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployerRefunds = (query = '') => {
  let data = axios
    .get(`admin/wallet-transactions/employers${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployeeWallets = (query = '') => {
  let data = axios
    .get(`admin/employeesWallets${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getPayrollBatches = (query = '') => {
  let qs = queryString.parse(query);
  if (!qs.sort_by) {
    qs.sort_by = 'id';
    qs.sort_order = 'desc';
  }

  let data = axios
    .get(`admin/payroll?${queryString.stringify(qs)}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getPayrollBatchesById = (id, query = '') => {
  let data = axios
    .get(`admin/payroll/${id}${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const addPayrollBatch = (data) => {
  let result = axios
    .post(`admin/payroll`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Batch created successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const deletePayrollBatch = (id) => {
  let result = axios
    .delete(`admin/payroll/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Batch removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const editPayrollBatch = (data, id) => {
  let result = axios
    .patch(`admin/payroll/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Batch updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Batch updates failed!' };
    });
  return result;
};

export const getPayrollPeriods = (query = '') => {
  let data = axios
    .get(`admin/payroll-periods${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getPayrollPeriodById = (id, query = '') => {
  let data = axios
    .get(`admin/payroll-periods/employers${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const addPayrollPeriod = (data) => {
  let result = axios
    .post(`admin/payroll-periods`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Payroll period added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const editPayrollPeriod = (data, id) => {
  let result = axios
    .patch(`admin/payroll-periods/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Payroll period successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Payroll period update failed!' };
    });

  return result;
};

export const deletePayrollPeriod = (id) => {
  let result = axios
    .delete(`admin/payroll-periods/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Payroll period removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const getPayrollRules = (query = '') => {
  let data = axios
    .get(`admin/payroll-rules${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getPayrollRuleById = (id, query = '') => {
  let data = axios
    .get(`admin/payroll-rules/${id}${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const addPayrollRule = (data) => {
  let result = axios
    .post(`admin/payroll-rules`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Payroll Rule created successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const editPayrollRule = (data, id) => {
  let result = axios
    .patch(`admin/payroll-rules/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Payroll rule updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Payroll rule update failed!' };
    });
  return result;
};

export const deletePayrollRule = (id) => {
  let result = axios
    .delete(`admin/payroll-rules/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Payroll rule removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const deleteShortlistedCandidate = (id) => {
  let result = axios
    .delete(`admin/employees/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Candidate removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};
// finance module end

export const unreadNotifications = (notifications) => {
  // type can be all, read, unread
  // make it handle
  let data = axios
    .post(`admin/delete-notifications`, { ids: notifications })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], status: 'failure' };
    });
  return data;
};

export const readNotifications = (notifications) => {
  // type can be all, read, unread
  // make it handle
  let data = axios
    .post(`admin/read-notifications`, { ids: notifications })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], status: 'failure' };
    });
  return data;
};

export const clearNotifications = (notifications) => {
  // type can be all, read, unread
  // make it handle
  let data = axios
    .post(`admin/delete-notifications`, { ids: notifications })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], status: 'failure' };
    });
  return data;
};

export const getNotifications = (type = 'all', params) => {
  // type can be all, read, unread
  // make it handle
  let query = queryString.stringify(params);

  let data = axios
    .get(`admin/notifications/${type}?${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], status: 'failure' };
    });
  return data;
};

export const readAllNotifications = () => {
  let data = axios
    .get(`admin/read-all`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], status: 'failure' };
    });
  return data;
};

export const shortlistCandidateFromSuggested = (data) => {
  let result = axios
    .post(`admin/shortlist-candidate`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data,
          message: 'Candidate shortlisted successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Candidate shortlisting failed!' };
    });
  return result;
};

export const offerRequest = (job_id, data) => {
  let result = axios
    .post(`admin/send-offer/${job_id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Offer sent successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Offer request failed!' };
    });
  return result;
};

export const sendOffer = (id, data) => {
  let result = axios
    .post(`admin/send-offer/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Offer sent successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Offer sending failed!' };
    });
  return result;
};

export const patchInterview = (data, id) => {
  let result = axios
    .patch(`admin/interviews/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Interview updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Interview update failed! Please try again.',
      };
    });
  return result;
};

export const updateInterview = (data) => {
  let result = axios
    .post(`admin/reschedule-interview`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: res.data.message,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Interview resechdule request failed!',
      };
    });
  return result;
};

// For requesting an interview from candidate
export const scheduleInterview = (data) => {
  let result = axios
    .post(`admin/schedule-interview`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: res.data.message,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Interview scheduling failed!' };
    });
  return result;
};

// For direct interview with the candidate
// export const scheduleDirectInterview = (data) => {
//   let result = axios
//     .post(`admin/scheduleInterview`, data)
//     .then((res) => {
//       if (res.data.status === "success")
//         return {
//           status: "success",
//           data: res.data.data,
//           message: res.data.message,
//         };
//       else
//         return {
//           status: "error",
//           errors: res.data.errors ? getErrors(res.data.errors) : null,
//           message: res.data.message ?? "Kindly fix the data!",
//         };
//     })
//     .catch((err) => {
//       return { status: "error", message: "Interview scheduling failed!" };
//     });
//   return result;
// };

export const createCompanyAdmin = (data) => {
  if (data.id === '' || data.id === null || data.id === undefined || data.id === -1) delete data.id;
  let result = axios
    .post(`admin/employerCompany`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Company added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Adding Company failed!' };
    });
  return result;
};

export const editCompanyAdmin = (data) => {
  if (data.id === '' || data.id === null || data.id === undefined || data.id === -1) delete data.id;
  let result = axios
    .patch(`admin/employerCompany/${data.id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Company updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Company update failed!' };
    });
  return result;
};

export const deleteCompany = (id) => {
  let result = axios
    .delete(`admin/employerCompany/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Company removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ? res.data.message : 'Some error occured!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const collectiveDeleteProfiles = (payload) => {
  let result = axios({
    method: 'delete',
    url: `admin/employeesJobProfile/0`,
    data: payload,
  })
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: `Profile removed successfully!`,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing profile!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing profile!',
      };
    });
  return result;
};
export const collectiveRecoverProfiles = (payload) => {
  let result = axios
    .post(`admin/employeesJobProfile/0/restore`, payload)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: `Profile removed successfully!`,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing profile!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing profile!',
      };
    });
  return result;
};

export const createPortfolioAdmin = (data, id) => {
  let result = axios
    .post('admin/jobPortfolio', data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Portfolio added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Adding Portfolio failed!' };
    });
  return result;
};

export const editPortfolioAdmin = (data, id) => {
  let result = axios
    .patch(`admin/jobPortfolio/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Portfolio updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Portfolio updates failed!' };
    });

  return result;
};

export const updateSkillsAdmin = (data) => {
  let result = axios
    .patch('admin/employeeSkills/1', data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Skills updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Skills update failed!' };
    });
  return result;
};

export const createLanguageAdmin = (data, id, employee_id) => {
  data.employee_id = employee_id;
  let url = `admin/employeeLanguage`;
  let result = axios
    .post(url, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Language add failed!' };
    });
  return result;
};

export const editLanguageAdmin = (data, id, employee_id) => {
  data.employee_id = employee_id;
  let result = axios
    .patch(`admin/employeeLanguage/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language updation successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Language updates failed!' };
    });

  return result;
};

export const editEducationAdmin = (data, id, employee_id) => {
  data.employee_id = employee_id;
  let result = axios
    .patch(`admin/employeeEducation/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Education updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Education updates failed!' };
    });

  return result;
};

export const createEducationAdmin = (data, id, employee_id) => {
  data.employee_id = employee_id;
  let result = axios
    .post('admin/employeeEducation', data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Education added successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Adding education failed!' };
    });
  return result;
};
// it's the resume
export const createJobProfileAdmin = (data) => {
  let result = axios
    .post('admin/employeesJobProfile', data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Profile created successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Adding job profile failed!' };
    });
  return result;
};

export const deleteJobAdmin = (delete_id) => {
  let result = axios
    .delete(`admin/employerJobs/${delete_id}`)
    .then((res) => {
      if (res.data.status === 'success') return res.data;
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const editJobPostAdmin = (data, id) => {
  if (data.id === '' || data.id === null || data.id === undefined || data.id === -1) delete data.id;
  let result = axios
    .patch(`admin/employerJobs/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success') {
        return {
          status: 'success',
          data: res.data,
          message: 'Job updated successfully!',
        };
      } else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Job update failed!' };
    });
  return result;
};

export const editJobProfileAdmin = (data, id) => {
  let result = axios
    .patch(`admin/employeesJobProfile/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Job Profile updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Job Profile update failed!' };
    });
  return result;
};

export const editJunkProfileAdmin = (data) => {
  let result = axios
    .post(`admin/update-profile-junk-status`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Job Profile updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Job Profile update failed!' };
    });
  return result;
};

export const editPrescreening = (data, id) => {
  let result = axios
    .patch(`admin/prescreening/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          message: 'Prescreening request updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Prescreening update failed!' };
    });
  return result;
};

export const adminDeleteLeave = (delete_id) => {
  let result = axios
    .delete(`admin/delete-employee-leaves/${delete_id}`)
    .then((res) => {
      if (res.data.status === 'success') return res.data;
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const adminUpdateLeaves = (data, id) => {
  let result = axios
    .patch(`admin/update-employee-leaves/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Leave application status changed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.message ? res.message : 'Leave application update failed!',
        };
    })
    .catch((err) => {
      return { status: 'error', errors: {}, message: 'Leave update failed!' };
    });
  return result;
};

export const getLeaves = (query) => {
  let data = axios
    .get(`admin/employee-leaves${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const deleteHoliday = (delete_id) => {
  let result = axios
    .delete(`admin/holidays/${delete_id}`)
    .then((res) => {
      if (res.data.status === 'success') return res.data;
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};

export const editHoliday = (id, data) => {
  let result = axios
    .patch(`admin/holidays/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Leave application status changed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.message ? res.message : 'Leave application update failed!',
        };
    })
    .catch((err) => {
      return { status: 'error', errors: {}, message: 'Leave update failed!' };
    });
  return result;
};
export const addHoliday = (data) => {
  let result = axios
    .post(`admin/holidays`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Holiday created successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Holiday creation failed' };
    });
  return result;
};

export const getHolidays = (query) => {
  let data = axios
    .get(`admin/holidays${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const restoreHoliday = (id) => {
  let result = axios
    .delete(`admin/holidays/${id}/restore`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Holiday restored successfully',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: res?.data?.message ?? 'Issue occured while restoring',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring',
      };
    });
  return result;
};

// disputes
export const getAttendanceDisputeById = (id) => {
  let data = axios
    .get(`admin/attendance-disputes/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getAttendanceDisputes = (query = '') => {
  let qs = queryString.parse(query);
  let data = axios
    .get(`admin/attendance-disputes?${queryString.stringify(qs)}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const updateAttendanceDispute = (id, status, data) => {
  let result = axios
    .patch(`admin/resolveAttendanceDispute/${id}/${status}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Dispute updated succesfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Some issue occured!' };
    });
  return result;
};

export const createDispute = (data) => {
  let result = axios
    .post(`admin/attendance-disputes`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          data: res.data.data,
          status: 'success',
          message: 'Dispute submitted successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Dispute submission failed!' };
    });
  return result;
};

// Get employer transactions
export const getEmployerTransactions = (employer_id, query) => {
  let data = axios
    .get(`admin/wallet-transactions/${employer_id}/employers${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

// Get employee transactions
export const getEmployeeTransactions = (employee_id, query) => {
  let data = axios
    .get(`admin/wallet-transactions/${employee_id}/employees${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

// Get employee Screenshots
export const getEmployeeMonitoringData = (query) => {
  let data = axios
    .get(`admin/monitoring-data${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

// Employers start
export const getEmployerById = (id) => {
  let data = axios
    .get(`admin/employers/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const updateEmployer = (id, data) => {
  let result = axios
    .patch(`admin/employers/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: res.data.message,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Cannot move the employer to trash',
      };
    });
  return result;
};

export const getEmployers = (query) => {
  let queryParams = queryString.parse(query);
  // queryParams.type="user"
  // query.type='user'
  if (queryParams.sort_by === undefined) {
    queryParams.sort_by = 'id';
    queryParams.sort_order = 'desc';
  }
  let data = axios
    .get(`admin/employers?${queryString.stringify(queryParams)}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getJobs = (id) => {
  let data = axios
    .get(`admin/employers/${id}/jobs`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const updateCandidateStatus = (job_id, data) => {
  let result = axios
    .post(`admin/updateCandidateStatus/${job_id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Data updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Candidate update failed!' };
    });
  return result;
};
// Employers end

// export const getEmployerInterviewTimingsByAppliedJobId = (applied_job_id) => {
//   let data = axios
//     .get(`admin/interview-time-by-employer/${applied_job_id}`)
//     .then((res) => {
//       let employees = res.data;
//       return employees;
//     })
//     .catch((err) => {
//       //
//       return { data: [], error: "Error occured" };
//     });
//   return data;
// };

// app settings start
export const getAppSettings = () => {
  let data = axios
    .get(`appSettingsall`)
    .then((res) => {
      let data = res.data.data;
      console.log(data);
      return data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const storeAllAppSettings = (data) => {
  return axios
    .post(`admin/storeAppSettings`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
};
// app settings end

// employee start
export const getEmployeeById = (id) => {
  let data = axios
    .get(`admin/employees/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

// Flagged Employees
export const flaggedEmployees = (queryParams) => {
  let data = axios
    .get(`admin/employees?${queryParams}&type=flaged`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

// export const updateEmployee = (id, data) => {
//   let result = axios
//     .patch(`admin/employees/${id}`, data)
//     .then((res) => {
//       if (res.data.status === "success")
//         return {
//           status: "success",
//           data: res.data.data,
//           message: "Employee successfully moved to trash",
//         };
//       else
//         return {
//           status: "error",
//           errors: res.data.errors ? getErrors(res.data.errors) : null,
//           message: res.data.message ?? "Kindly fix the data",
//         };
//     })
//     .catch((err) => {
//       return { status: "error", errors: {}, message: "Cannot move the employee to trash" }
//     });
//   return result;
// };

export const getEmployeeTimesheet = (applied_job_id, query) => {
  let data = axios
    .get(`admin/employee-timesheet/${applied_job_id}${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};
// employee end

// Applicants
export const getApplicants = (query, type) => {
  let queryParams = queryString.parse(query);
  if (queryParams.type === undefined) queryParams.type = type;
  let data = axios
    .get(`admin/employees?${queryString.stringify(queryParams)}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getApplicantById = (id) => {
  let data = axios
    .get(`admin/applicant/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getApplicantEmployerInterviews = (id, query) => {
  let data = axios
    .get(`admin/interviewsByEmployee/${id}?${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const updateApplicant = (id, data) => {
  let result = axios
    .patch(`admin/employees/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Applicant successfully moved to trash',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Cannot move the applicant to trash',
      };
    });
  return result;
};

// job profiles
export const getJobProfiles = (query, status = 0) => {
  let queryParams = queryString.parse(query);
  console.log(query);

  if (status === 'disabled') {
    delete queryParams.status;
    queryParams.is_disabled = 1;
    if (queryParams.name || queryParams.email || queryParams.phone || queryParams.job_title_id) {
      let data = axios
        .get(
          `admin/employeesJobProfile?${queryString.stringify(queryParams, {
            arrayFormat: 'bracket',
          })}`,
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          return { data: [], error: 'Error occured' };
        });
      return data;
    }
    let data = axios
      .get(
        `admin/employeesJobProfile?${queryString.stringify(
          { is_disabled: 1 },
          {
            arrayFormat: 'bracket',
          },
        )}`,
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return { data: [], error: 'Error occured' };
      });
    return data;
  }

  if (status === 'junk') {
    delete queryParams.status;
    queryParams.is_junk = 1;
    if (queryParams.name || queryParams.email || queryParams.phone || queryParams.job_title_id) {
      let data = axios
        .get(
          `admin/employeesJobProfile?${queryString.stringify(queryParams, {
            arrayFormat: 'bracket',
          })}`,
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          return { data: [], error: 'Error occured' };
        });
      return data;
    }
    let data = axios
      .get(
        `admin/employeesJobProfile?${queryString.stringify(
          { is_junk: 1 },
          {
            arrayFormat: 'bracket',
          },
        )}`,
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return { data: [], error: 'Error occured' };
      });
    return data;
  }

  // Normal Call
  queryParams.status = status;
  // queryParams.active_status = 2;
  let data = axios
    .get(
      `admin/employeesJobProfile?${queryString.stringify(queryParams, {
        arrayFormat: 'bracket',
      })}`,
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getJobProfileById = (id) => {
  let data = axios
    .get(`admin/employeesJobProfile/${id}`)
    .then((res) => {
      if (res.data && res.data.status === 'success') {
        return res.data;
      } else {
        return { data: {}, status: 'error', message: res.message };
      }
    })
    .catch((err) => {
      return { data: {}, status: 'error', message: err.message };
    });

  return data;
};

export const addProfileReview = (data) => {
  let result = axios
    .post(`admin/employeesJobProfile/review`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Profile reviewed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Profile review failed!' };
    });
  return result;
};

export const editProfileReview = (data) => {
  let result = axios
    .patch(`admin/employeesJobProfile/${data.id}/review`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Profile review updated successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Profile review update failed!' };
    });
  return result;
};

export const deleteExperience = (id) => {
  let result = axios
    .delete(`admin/employeeExperience/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Experience removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};
export const deleteEducation = (id) => {
  let result = axios
    .delete(`admin/employeeEducation/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Education removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,

          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};
export const deleteLanguage = (id) => {
  let result = axios
    .delete(`admin/employeeLanguage/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Language removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};
export const deletePortfolio = (id) => {
  let result = axios
    .delete(`admin/jobPortfolio/${id}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Portfolio removed successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: 'Issue occured while removing!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while removing!',
      };
    });
  return result;
};
// Job Profiles End

// Job Postings Start
export const getJobPostings = (query) => {
  let queryParams = queryString.parse(query);
  queryParams.sort_by = 'created_at';
  queryParams.sort_order = 'desc';

  if (queryParams.is_closed === undefined) {
    queryParams.is_closed = 0;
  }
  if (queryParams.status === undefined && !queryParams.is_closed) {
    queryParams.status = [1];
  }
  let querystring = queryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });

  // queryParams.status = status
  let data = axios
    .get(`admin/employerJobs?${querystring}`)
    .then((res) => {
      let jobPostings = res.data.data;
      return jobPostings;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const getClosedReasonList = () => {
  let data = axios.get('admin/jobclosedreasons').then((res) => {
    return res.data.data;
  });
  return data;
};

export const storeClosedReason = (request) => {
  let data = axios.post('admin/jobclosedreasons', request).then((res) => {
    return res.data;
  });
  return data;
};

export const editClosedReason = (request, id) => {
  let data = axios.patch(`admin/jobclosedreasons/${id}`, request).then((res) => {
    return res.data;
  });
  return data;
};

export const deleteClosedReason = (id) => {
  let data = axios.delete(`admin/jobclosedreasons/${id}`).then((res) => {
    return res.data;
  });
  return data;
};

export const getJobPostDetailAdmin = (id) => {
  let data = axios
    .get(`admin/employerJobs/${id}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured' };
    });
  return data;
};

export const updateJobPost = (id, data) => {
  let result = axios
    .patch(`admin/employerJobs/${id}`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job Post updated successfully',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', errors: {}, message: 'Job Post update failed' };
    });
  return result;
};
// TODO: NEED API

export const getJobCandidates = (jobId, candidateStatuses, query = '') => {
  let queryParams = queryString.parse(query);
  queryParams.status = candidateStatuses.status;
  let querystring = queryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });

  let data = axios
    .get(`admin/job-candidates/${jobId}?${querystring}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });
  return data;
};

export const getJobSuggestedCandidates = (jobId, query = '') => {
  let data = axios
    .get(`admin/suggested-candidate/${jobId}${query}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return { data: [] };
    });

  return data;
};

export const shortlistCandidate = (data) => {
  let result = axios
    .post(`admin/shortlist-candidate`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Candidate shortlisted successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Candidate Shortlisting failed!' };
    });
  return result;
};
// Job Postings End

//old employees
export const getResignedTerminatedEmployee = (query) => {
  return axios
    .get(`admin/ex-employee?${query}`)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Shortlisted Successfully!' };
    });
};

export const getEmployerForCRM = (type = 'prospects', query) => {
  return axios
    .get(`admin/${type}${query}`)
    .then((res) => {
      if (res.data !== undefined)
        return {
          status: 'success',
          data: res.data.data,
        };
      else
        return {
          status: 'error',
          errors: res?.data?.errors ? getErrors(res.data.errors) : null,
          message: res?.data?.message ?? 'Kindly fix the data!',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Shortlisted failed!' };
    });
};

export const getDashboardData = (__) => {
  return axios.get('admin/dashboard').then((result) => {
    return result.data;
  });
};

export const getLatestJobs = (__) => {
  return axios.get('admin/latestJobs').then((result) => {
    return result.data;
  });
};

export const getRecentInterviews = (__) => {
  return axios.get('admin/recentInterview').then((result) => {
    return result.data;
  });
};

export const employeesRecentAttendance = (__) => {
  return axios.get('admin/employeesAttendance').then((result) => {
    return result.data;
  });
};

export const employeesRecentLeaves = (__) => {
  return axios.get('admin/employeesLeaves').then((result) => {
    return result.data;
  });
};

export const getGoals = (__) => {
  return axios.get('admin/employeesLeaves').then((result) => {
    let temp = {};
    temp.data = [];
    return temp;
  });
};

export const getAnnouncements = (__) => {
  return axios.get('admin/employeesLeaves').then((result) => {
    let temp = {};
    temp.data = [];
    return temp;
  });
};

// Reporting APIs
export const getEmployerReportCount = (query) => {
  let data = axios
    .get(`admin/employer-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployerReportList = (query) => {
  let data = axios
    .get(`admin/employer-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployeesReportCount = (query) => {
  let data = axios
    .get(`admin/employee-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployeesReportList = (query) => {
  let data = axios
    .get(`admin/employee-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getApplicantsReportCount = (query) => {
  let data = axios
    .get(`admin/applicant-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getApplicantsReportList = (query) => {
  let data = axios
    .get(`admin/applicant-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getCandidatesReport = (query) => {
  let data = axios
    .get(`admin/candidate-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getCandidatesReportStats = (query = '') => {
  let data = axios
    .get(`admin/candidate-report-stats${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getJobProfileReportList = (query) => {
  let data = axios
    .get(`admin/employeesJobProfile${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getJobPostReports = (query) => {
  let data = axios
    .get(`admin/employerJobs${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployerFinanceReportCount = (query) => {
  let data = axios
    .get(`admin/employer-finance-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployerFinanceReportList = (query) => {
  let data = axios
    .get(`admin/employer-finance-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployeeFinanceReportCount = (query) => {
  let data = axios
    .get(`admin/employee-finance-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getEmployeeFinanceReportList = (query) => {
  let data = axios
    .get(`admin/employee-finance-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getServiceFeeCount = (query) => {
  let data = axios
    .get(`admin/servicefee-report-counters${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getServiceFeeList = (query) => {
  let data = axios
    .get(`admin/servicefee-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getWithdrawalsReport = (query) => {
  let data = axios
    .get(`admin/withdrawal-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getDepositsReport = (query) => {
  let data = axios
    .get(`admin/deposits-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getRefundsReport = (query) => {
  let data = axios
    .get(`admin/refunds-report-list${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const getFinanceKPIReport = (query) => {
  let data = axios
    .get(`admin/finance-report${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { data: [], error: 'Error occured', message: 'Error occured' };
    });
  return data;
};

export const createJobPostAdmin = (data) => {
  if (data.id === '' || data.id === null || data.id === undefined || data.id === -1) delete data.id;
  let result = axios
    .post(`admin/employerJobs`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Job restored successfully!',
        };
      else
        return {
          status: 'error',
          errors: getErrors(res.data.errors),
          message: result?.message ?? 'Issue occured while restoring!',
        };
    })
    .catch((err) => {
      return {
        status: 'error',
        errors: {},
        message: 'Issue occured while restoring!',
      };
    });
  return result;
};

export const employeeTerminateAdmin = (data) => {
  let result = axios
    .post(`admin/terminate-employee`, data)
    .then((res) => {
      if (res.data.status === 'success')
        return {
          status: 'success',
          data: res.data.data,
          message: 'Employee termination requested successfully!',
        };
      else
        return {
          status: 'error',
          errors: res.data.errors ? getErrors(res.data.errors) : null,
          message: res.data.message ?? 'Kindly fix the data',
        };
    })
    .catch((err) => {
      return { status: 'error', message: 'Termination request failed!' };
    });
  return result;
};

export const downloadPayslip = (transaction_id) => {
  let result = axios
    .get(`admin/payslip/${transaction_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { result: {}, status: 'failure' };
    });
  return result;
};

// Employee Performance Review
export const getPerformanceReviews = (params) => {
  let data = axios
    .get(`admin/employee-performance-review${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return {
        status: 'error',
        message: 'Some issue has occured, Please try again later',
      };
    });
  return data;
};
