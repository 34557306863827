import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function Password(props) {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <TextField
      required={props.required}
      variant={props.variant}
      label={props.label}
      name={props.name}
      tabIndex={props.tabIndex}
      type={showPassword ? "text" : "password"}
      autoComplete={props.autoComplete ?? ""}
      onChange={props.onChange}
      onBlur={(e) => props.onBlur(e)}
      onFocus={(e) => { if (props.onFocus) props.onFocus(e); }}
      fullWidth={props.fullWidth}
      value={props.value}
      error={props.error ? true : false}
      helperText={props.error && props.helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" variant="filled" tabIndex={-1}>
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => toggleShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default Password;
