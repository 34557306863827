import React from "react";
import { Backdrop, withStyles } from "@material-ui/core";
import Loader from "../WorktuallyLoader";

const FullscreenLoader = (props) => {
  let { classes } = props;
  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Loader />
    </Backdrop>
  );
};

const materialStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

export default withStyles(materialStyles)(FullscreenLoader);