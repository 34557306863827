import * as ActionTypes from "../actionTypes";
import moment from "moment";

// TODO: we might also have to add checkout time
// checkinStatus is 1 for checked in 2 for checkout
export const notifications = (
  state = { isLoading: true, unread_count: 0, notifications: [] },
  action
) => {
  let notifications = []
  let matches = 0
  switch (action.type) {
    // TODO: Send time from component
    case ActionTypes.CLEAR_NOTIFICATIONS:
      notifications = state.notifications.filter((item, index) => {
        if (item.id === action.payload[index]) {
          if (item.read_at === null)
            matches += 1
          return false
        }
        return true
      })
      // check if no notification is left
      // if none left; API 
      return { ...state, notifications, isLoading: action.setLoading, unread_count: state.unread_count - matches }

    case ActionTypes.ADD_NOTIFICATIONS:
      return { isLoading: false, notifications: [].concat([...action.payload.notifications, ...state.notifications]), unread_count: action.payload.unread_count ?? state.unread_count + 1 }
    
    case ActionTypes.MARK_AS_READ:
      notifications = state.notifications.map((item, index) => {
        if (action.payload.includes(item.id) === true) {
          matches += 1
          return { ...item, read_at: moment(), }
        }
        else
          return item
      })
      return { ...state, notifications, unread_count: state.unread_count - matches }

      case ActionTypes.MARK_AS_UNREAD:
        notifications = state.notifications.map((item, index) => {
          if (action.payload.includes(item.id) === true) {
            matches += 1
            return { ...item, read_at: null, }
          }
          else
            return item
        })
        return { ...state, notifications, unread_count: state.unread_count + matches }

    case ActionTypes.REMOVE_NOTIFICATION: 
      notifications = state.notifications.filter((item, index) => {
        if (action.payload.includes(item.id) === true) {
          if (item.read_at === null)
            matches += 1
          return false
        }
        else
          return true
      })
      return { ...state, notifications, unread_count: state.unread_count - matches }
      
    case ActionTypes.CLEAR_NOTFICATIONS_LOGOUT:
      return { isLoading: true, unread_count: 0, notifications: [] }
    default:
      return state;
  }
};
