import * as ActionTypes from "../actionTypes";

export const drawer = (state = { isExpanded: true }, action) => {
  switch (action.type) {
    case ActionTypes.SET_DRAWER_EXPAND:
      return { ...state, isExpanded: action.payload };
    case ActionTypes.TOGGLE_DRAWER_EXPAND:
      return { ...state, isExpanded: !state.isExpanded };

    default:
      return state;
  }
};
