import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import { ReactComponent as ErrorCloudSvg } from "../../assets/images/error.svg";
import { ReactComponent as ForbiddenSvg } from "../../assets/images/403.svg";

// We can render any custom fallback UI depending upon the errors returned.
class CrashPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI for UI crashes.
    return { hasError: true };
  }

  render() {
    let { classes } = this.props;

    if (this.state.hasError && localStorage.getItem("error") === '403') {
      return (
        <div className={classes.errContainer}>
          <div className={classes.errCentralContent}>
            <ForbiddenSvg color="black" width="51rem" height="18rem" />
            <Typography className="heading">Access Denied/Forbidden</Typography>
            <Typography className="text">
              The page your are trying to reach is absolutely forbidden for some reason
            </Typography>
          </div>
        </div>
      );
    }
    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <div className={classes.centralContent}>
            <ErrorCloudSvg width="24rem" height="17rem" />
            <Typography className="heading">Aw, Snap!</Typography>
            <Typography className="text">
              Something went wrong while displaying this page. To continue
              please press{" "}<a href='/' onClick={() => window.location.reload(false)} className="link underscore white-text">reload</a>{" "}
              or go to another page.
            </Typography>
          </div>
        </div>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}

const materialStyles = (theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#006AB3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centralContent: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > p": { color: "inherit" },
    "& > .heading": {
      fontSize: "3.125rem",
      fontWeight: 500,
      marginBottom: ".325rem",
    },
    "& > .text": {
      fontSize: "1.25rem",
      textAlign: "center"
    }
  },
  errContainer: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  errCentralContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .heading": {
      color: "#4C4D58",
      fontSize: "2.75rem",
      fontWeight: 500,
      marginBottom: ".325rem",
    },
    "& > .text": {
      color: "#909090",
      textAlign: "center"
    }
  }
});

export default withStyles(materialStyles)(CrashPage);