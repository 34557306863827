import React, { useEffect } from "react";
import { withStyles, Typography, Fade } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import RoleSwitch from "../RoleSwitch";
import { ReactComponent as EmployeeReg } from "../../assets/images/employee-reg.svg";
import { ReactComponent as EmployerReg } from "../../assets/images/employer-reg.svg";
import G1 from "../../assets/images/g1.svg";
import G2 from "../../assets/images/g2.svg";

// This is the form container for login or sign up
const Form = (props) => {
  let { classes } = props;

  useEffect(() => {
    document.querySelector("body").className = "white-bc";
    return () => document.querySelector("body").classList.remove("white-bc");
  });

  return (
    <form
      noValidate
      className={classes.formContainer}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className={classes.formInner}>
        <div className={classes.textsContainer}>
          <Typography className={classes.loginTitle}>{props.text} </Typography>
          {props.role !== "admin" && (
            <RoleSwitch role={props.role} onChange={props.handleRoleSwitch} />
          )}
        </div>
        {props.children}
      </div>
    </form>
  );
};

const formStyles = (theme) => ({
  formContainer: {
    width: "42%",
    height: "100%",
    padding: "2rem 4.75rem",
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down(1400)]: {
      padding: "2.5rem 4.75rem",
    },
    [theme.breakpoints.between(960, 1268)]: {
      width: "55%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formInner: {
    width: "100%",
    maxWidth: "31rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  textsContainer: {
    marginBottom: "2.25rem",
  },
  loginTitle: {
    fontSize: "2.0rem",
    fontWeight: 500,
    marginBottom: "1rem",
  },
});

export const FormStyled = withStyles(formStyles)(Form);

// This container renders the whole screen for login or sign up
const AuthContainer = (props) => {
  let { classes } = props;
  let { role, text, handleRoleSwitch } = props;
  return (
    <div className={classes.container}>
      <FormStyled role={role} text={text} handleRoleSwitch={handleRoleSwitch}>
        {props.children}
      </FormStyled>
      <div className={classes.sliderContainer}>
        {/* images slider */}
        <div className={classes.imageContainer}>
          <div className={classes.absoluteContent}></div>
          <Fade in={role === "employer" || role === "employee"} key={role}>
            {role === "employer" ? (
              <EmployerReg width="34rem" />
            ) : (
              <EmployeeReg width="28rem" />
            )}
          </Fade>
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <img alt="reg" src={G1} style={{ width: "14rem" }} />
          </div>
          <div style={{ position: "absolute", bottom: -8, left: 0 }}>
            <img alt="" src={G2} style={{ width: "3rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
const containerStyles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    minHeight: "100vh",
    [theme.breakpoints.up("xl")]: {
      height: "100vh",
    },
  },
  // images slider
  sliderContainer: {
    width: "58%",
    height: "100vh",
    position: "fixed",
    right: 0,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    [theme.breakpoints.between(960, 1268)]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "50%",
    },
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
    position: "relative",
  },
  absoluteContent: {
    top: "50vh",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // for the logo
    "& > img": {
      width: "10rem",
      cursor: "pointer",
    },
    // title text
    "& > :nth-child(2)": {
      fontSize: "2.125rem",
      color: "#fff",
      marginBottom: "0.2rem",
      whiteSpace: "nowrap",
    },
    // title text
    "& > :nth-child(3)": {
      fontSize: "1.125rem",
      color: "#fff",
      textAlign: "center",
    },
  },
});

export default withRouter(withStyles(containerStyles)(AuthContainer));