import { usermavenClient } from '@usermaven/sdk-js';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useUsermaven(opts) {
  const usermaven = useMemo(() => usermavenClient(opts), [opts]);
  return { usermaven };
}

function usePageView(usermaven, event) {
  let location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production' && !location.pathname.includes('admin')) {
      usermaven.track('pageview');
    }
  }, [location, usermaven]);
}

export { useUsermaven, usePageView };
