import * as ActionTypes from '../actionTypes';
// TODO: we might also have to add checkout time

// checkinStatus is 1 for checked in 2 for checkout
export const employerInfo = (
  state = {
    balance: 0,
    card_brand: '',
    card_last_four: '',
    expiry_month: '',
    expiry_year: '',
    charge_amount: 0,
    payment_method: false,
    show_tab: true,
    isLoading: true,
    is_company_exist: false,
    has_employee: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_EMPLOYER_INFO:
      delete action.payload.client_secret;
      delete action.payload.public_key;
      return { ...state, ...action.payload, isLoading: false };
    case ActionTypes.CLEAR_EMPLOYER:
      return {
        balance: 0,
        card_brand: '',
        card_last_four: '',
        expiry_month: '',
        expiry_year: '',
        charge_amount: 0,
        payment_method: false,
        show_tab: true,
        isLoading: true,
        is_company_exist: false,
        has_employee: false,
      };
    default:
      return state;
  }
};
