import React from "react";
import { withStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const CustomCheckbox = (props) => {
  let { classes, isChecked, handleChange, isDisabled } = props;

  return (
    <div
      tabIndex={3}
      className={classes.checkbox}
      style={{ backgroundColor: props.bcColor }}
      onClick={isDisabled ? () => 1 : handleChange}
    >
      {isChecked && <CheckIcon style={{ fontSize: "1rem" }} />}
    </div>
  );
};

const materialStyles = (theme) => ({
  checkbox: {
    backgroundColor: "#DEE2E6",
    height: "1.5rem",
    width: "1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.3125rem",
    cursor: "pointer",
    "& > img": {
      fontSize: "1rem",
      objectFit: "cover",
    },
  },
});
export default withStyles(materialStyles)(CustomCheckbox);
