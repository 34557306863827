import Axios from "axios";
import CONSTANTS from "./constants";
import { setupCache } from "axios-cache-adapter";

let clearReduxCallback = undefined;

const axios = Axios.create({ baseURL: CONSTANTS.apiBaseURL });

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  debug: true,
  exclude: { query: false }
});

const cachedAxios = Axios.create({
  baseURL: CONSTANTS.apiBaseURL,
  adapter: cache.adapter
});

axios.CancelToken = Axios.CancelToken;
axios.interceptors.response.use((res) => {
  localStorage.setItem("error", null);
  return res;
},
  async (error) => {
    // if user is unauthorized
    if (error.response?.status === 401) {
      await localStorage.removeItem("user");
      await localStorage.removeItem("user_role");
      await localStorage.removeItem("user_verif");
      await localStorage.removeItem("skip_2fa");
      if (clearReduxCallback !== undefined) {
        clearReduxCallback();
      }
    }
    else localStorage.setItem("error", error.response?.status)
    return error;
  }
);

export const setClearRedux = (callback) => {
  clearReduxCallback = callback;
};

export const updateAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    cachedAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete cachedAxios.defaults.headers.common["Authorization"];
  }
};

export { axios, cachedAxios, cache };