// drawer related actions
export const SET_DRAWER_EXPAND = "SET_DRAWER_EXPAND";
export const TOGGLE_DRAWER_EXPAND = "TOGGLE_DRAWER_EXPAND";
export const HANDLE_SCREEN_SIZE_CHANGE = "HANDLE_SCREEN_SIZE_CHANGE";

// users related actions
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const UPDATE_USER = "UPDATE_USER";

// checkin related actions
export const TOOGLE_CHECKIN = "TOOGLE_CHECKIN";
export const CLEAR_CHECKIN = "CLEAR_CHECKIN"

// Employee Specific actions
export const SET_EMPLOYEE_INFO = "SET_EMPLOYEE_INFO"
export const SET_BALANCE = "SET_BALANCE"
export const SET_APPLIEDJOB_ID = "SET_APPLIEDJOBID"
export const SET_ATTENDANCE_ID = "SET_ATTENDANCE_ID"
export const SET_CHECKIN_TIME = "SET_CHECKIN_TIME"
export const SET_CHECKIN_STATUS = "SET_CHECKIN_STATUS"
export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE"

// EMPLOYER Specific actions
export const SET_EMPLOYER_INFO = "SET_EMPLOYER_INFO"
export const SET_EMPLOYER_BALANCE = "SET_EMPLOYER_BALANCE"
export const CLEAR_EMPLOYER = "CLEAR_EMPLOYER"

//  NOIFICATIONS
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS"
export const MARK_AS_READ = "MARK_AS_READ"
export const MARK_AS_UNREAD = "MARK_AS_UNREAD"
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const CLEAR_NOTFICATIONS_LOGOUT = "CLEAR_NOTFICATIONS_LOGOUT"
// export const CLEAR_NOTIF = "ADD_NOTIFICATIONS"