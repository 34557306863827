import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { drawer } from "./reducers/drawer";
import { user } from "./reducers/user";
import { employeeInfo } from "./reducers/employee";
import { employerInfo } from "./reducers/employer";
import { notifications } from "./reducers/notifications";

const store = createStore(
  combineReducers({ drawer, user, employeeInfo, employerInfo, notifications }),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
