import React, { Component } from "react";
import {
  withStyles,
  Typography,
  ButtonBase,
  Fade,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import CustomCheckbox from "../CustomCheckbox";
import { runValidator } from "../../utils/validations";
import clonedeep from "lodash.clonedeep";
import SuccessModal from "../../components/SuccessModal";
import PasswordWithStrength from "../../components/PasswordWithStrength";
import Password from "../../components/Password";
import {
  findAccount,
  sendCode,
  verifyCode,
  resetPassword
} from "../../apis/user";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      emailHash: null,
      resetMethod: "email",
      code: "",
      password: "",
      password_confirmation: "",
      logout: false,
      step: 1, // first step: 1
      errors: {},
      isSubmitLoading: false,
      submitMessage: false,
      seconds: 30,
      showPassword: false,
      showConfirmPassword: false,
      showToast: null,
      showClose: false,
    };
    this.timer = null;
    this.INPUTS = {
      email: {
        rules: { required: true, email: true },
        errorMessage: "Provide an email address. ",
      },
      code: {
        rules: { required: true, alphanumeric: true, minLength: 6 },
        errorMessage: "Provide the code. ",
      },
      password: {
        rules: {
          required: true,
          regex: new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
        },
        errorMessage: "Provide a password. ",
      },
      password_confirmation: {
        rules: { required: true },
        errorMessage: "Provide a confirmation password. ",
      },
    };
  }

  reduceSecond = () => {
    if (this.state.seconds === 0) {
      clearInterval(this.timer);
      return;
    }
    this.setState({ seconds: this.state.seconds - 1 });
  };

  handleNext = () => {
    this.setState({ isSubmitLoading: true });
    let errors = this.validateAll();
    if (Object.keys(errors).length !== 0) {
      this.setState({
        errors,
        isSubmitLoading: false,
        submitMessage: {
          status: "error",
          message: "Fix the inputs"
        }
      });
      return;
    }
    else
      if (this.state.step === 1) {
        findAccount({ email: this.state.email, type: this.props.role }).then((result) => {
          if (result?.status === "success") {
            this.setState({
              step: this.state.step + 1,
              emailHash: result.data.email,
              isSubmitLoading: false,
            });
          } else {
            this.setState({
              isSubmitLoading: false,
              errors: result.errors ? result.errors : {}
            });
          }
        }).catch((error) => {
          this.setState({
            isSubmitLoading: false,
            errors: error.errors ?? {},
            showToast: {
              status: "error",
              message: error.message ?? "Some error has occured",
            },
          });
        });
      } else if (this.state.step === 2) {
        // start the interval of the step 3 since it
        this.setState(
          { isSubmitLoading: false, step: this.state.step + 1 },
          this.handleSendCode
        );
      } else if (this.state.step === 3) {
        // api call verifyCode
        verifyCode({
          code: this.state.code,
          email: this.state.email,
          type: this.props.role,
        })
          .then((result) => {
            if (result.status === "success") {
              this.setState({
                step: this.state.step + 1,
                isSubmitLoading: false
              });
            } else {
              this.setState({
                isSubmitLoading: false,
                errors: result.errors ?? {}
              });
            }
          })
          .catch((error) => {
            this.setState({
              isSubmitLoading: false,
              errors: error.errors ?? {},
              showToast: {
                status: "error",
                message: error.message ?? "Some error has occured",
              },
            });
          });
      } else if (this.state.step === 4) {
        resetPassword({
          email: this.state.email,
          type: this.props.role,
          password: this.state.password,
          password_confirmation: this.state.password_confirmation,
          logged_out: this.state.logout,
        })
          .then((result) => {
            if (result.status === "success") {
              this.setState({
                step: this.state.step + 1,
                isSubmitLoading: false,
              });
            } else {
              this.setState({
                isSubmitLoading: false,
                errors: result.errors ?? {},
                showToast: {
                  status: "error",
                  message: result.message ?? "Some error has occured",
                },
              });
            }
          })
          .catch((error) => {
            this.setState({
              isSubmitLoading: false,
              errors: error.errors ?? {},
              showToast: {
                status: "error",
                message: error.message ?? "Some error has occured",
              },
            });
          });
      } else if (this.state.step === 5) {
        this.props.handleClose();
      }
  };

  handleInputChange = (e) => {
    this.clearErrorIfExists(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInputToogle = (checkbox) => {
    this.setState({ [checkbox]: !this.state[checkbox] });
  };

  // VALIDATIONS PART
  handleValidation = (e) => {
    let errors = runValidator(e.target.value, this.INPUTS[e.target.name]);

    if (
      e.target.name === "password" ||
      e.target.name === "password_confirmation"
    ) {
      if (
        this.state.password_confirmation !== "" &&
        this.state.password !== this.state.password_confirmation
      ) {
        this.setState({
          errors: {
            password_confirmation:
              "Password and confirmation password don't match",
          },
        });
        return;
      }
    }
    if (errors.length > 0) {
      let uppdatedErrors = clonedeep(this.state.errors);

      this.setState({
        errors: { ...uppdatedErrors, [e.target.name]: errors }
      });
    } else {
      let uppdatedErrors = clonedeep(this.state.errors);
      delete uppdatedErrors[e.target.name];
      this.setState({ errors: uppdatedErrors });
    }
  };

  validateAll = () => {
    let errors = {};

    let testFields = [];
    if (this.state.step === 1) testFields = ["email"];
    else if (this.state.step === 3) testFields = ["code"];
    else if (this.state.step === 4)
      testFields = ["password", "password_confirmation"];

    for (let field of testFields) {
      let fieldErrors = runValidator(this.state[field], this.INPUTS[field]);
      if (fieldErrors.length > 0) errors[field] = fieldErrors;
    }
    return errors;
  };

  clearErrorIfExists = (name) => {
    if (this.state.errors[name]) {
      let uppdatedErrors = clonedeep(this.state.errors);
      delete uppdatedErrors[name];
      this.setState({ errors: uppdatedErrors });
    }
  };

  clearSubmitMessage = () => {
    this.setState({ submitMessage: false });
  };

  handleSendCode = () => {
    // api call
    sendCode({ email: this.state.email, type: this.props.role })
      .then((result) => {
        if (result.status === "success") {
          // if success
          this.setState({ seconds: 30 });
          setInterval(this.reduceSecond, 1000);
        } else {
          this.setState({
            showToast: {
              status: "error",
              message: result.message
                ? result.message
                : "Some error has occured",
            },
          });
        }
      })
      .catch((error) => {
        this.setState({
          showToast: {
            status: "error",
            message: error.message ? error.message : "Some error has occured",
          },
        });
      });
  };

  render = () => {
    let { classes } = this.props;
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({ showClose: true })}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle className={classes.head}>
          {this.state.step === 1 && (
            <Fade in={this.state.step === 1}>
              <div>
                <Typography className={classes.title}>
                  Find Your Account
                </Typography>
                <Typography className={classes.subText}>
                  Please provide your account email for which you want to reset
                  your password!
                </Typography>
              </div>
            </Fade>
          )}
          {this.state.step === 2 && (
            <Fade in={this.state.step === 2}>
              <div>
                <Typography className={classes.title}>
                  Reset Your Password
                </Typography>
                <Typography className={classes.subText}>
                  How do you want to receive the code to reset your password?
                </Typography>
              </div>
            </Fade>
          )}
          {this.state.step === 3 && (
            <Fade in={this.state.step === 3}>
              <div>
                <Typography className={classes.title}>
                  Enter security code
                </Typography>
                <Typography className={classes.subText}>
                  Please check your email for a message with your code.{" "}
                </Typography>
              </div>
            </Fade>
          )}
          {this.state.step === 4 && (
            <Fade in={this.state.step === 4}>
              <div>
                <Typography className={classes.title}>
                  Change Password
                </Typography>
                <Typography className={classes.subText}>
                  Create a new, strong password that you don't use for other
                  websites{" "}
                </Typography>
              </div>
            </Fade>
          )}
          {this.state.step === 5 && (
            <Fade in={this.state.step === 5}>
              <div>
                <Typography className={classes.title}>
                  Password Changed Successfully
                </Typography>
                <Typography className={classes.subText}>
                  Your password has been updated successfully.{" "}
                </Typography>
              </div>
            </Fade>
          )}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <div className={classes.body}>
            {/* step one */}
            {this.state.step === 1 && (
              <Fade in={this.state.step === 1}>
                <TextField
                  required
                  variant="outlined"
                  label="Email"
                  tabIndex={1}
                  name="email"
                  fullWidth={true}
                  onChange={this.handleInputChange}
                  onBlur={this.handleValidation}
                  onFocus={() => this.clearErrorIfExists("email")}
                  error={this.state.errors.email ? true : false}
                  helperText={this.state.errors.email && this.state.errors.email}
                />
              </Fade>
            )}
            {/* step two */}
            {this.state.step === 2 && (
              <Fade in={this.state.step === 2}>
                <div className={classes.step2Container}>
                  <Radio
                    name="method"
                    value="email"
                    color="primary"
                    style={{ marginRight: "0.625rem" }}
                    checked={this.state.resetMethod === "email"}
                  />
                  <div>
                    <Typography>Send code via email</Typography>
                    <Typography>{this.state.emailHash}</Typography>
                  </div>
                </div>
              </Fade>
            )}
            {this.state.step === 3 && (
              <Fade in={this.state.step === 3}>
                <div className={classes.securityCodeContainer}>
                  <TextField
                    required
                    variant="outlined"
                    label="Code"
                    tabIndex={1}
                    name="code"
                    fullWidth={false}
                    inputProps={{ maxLength: 6 }}
                    onChange={this.handleInputChange}
                    onBlur={this.handleValidation}
                    onFocus={() => this.clearErrorIfExists("code")}
                    error={this.state.errors.code ? true : false}
                    helperText={this.state.errors.code && this.state.errors.code}
                  />
                  <Typography>
                    We sent your code to: {this.state.emailHash}
                  </Typography>
                </div>
              </Fade>
            )}
            {this.state.step === 4 && (
              <Fade in={this.state.step === 4}>
                <div className={classes.changePasswordContainer}>
                  <PasswordWithStrength
                    name="password"
                    variant="outlined"
                    label="New Password"
                    tabIndex={1}
                    value={this.state.password}
                    fullWidth={true}
                    onChange={this.handleInputChange}
                    onBlur={this.handleValidation}
                    onFocus={() => this.clearErrorIfExists("password")}
                    error={this.state.errors.password ? true : false}
                    helperText={
                      this.state.errors.password && this.state.errors.password
                    }
                  />
                  <Password
                    name="password_confirmation"
                    variant="outlined"
                    label="New Password Cofirmation"
                    tabIndex={2}
                    value={this.state.password_confirmation}
                    fullWidth={true}
                    onChange={this.handleInputChange}
                    onBlur={this.handleValidation}
                    onFocus={() =>
                      this.clearErrorIfExists("password_confirmation")
                    }
                    error={
                      this.state.errors.password_confirmation ? true : false
                    }
                    helperText={
                      this.state.errors.password_confirmation &&
                      this.state.errors.password_confirmation
                    }
                  />

                  <div className={classes.logout}>
                    <CustomCheckbox
                      isChecked={this.state.logout}
                      name="logout"
                      handleChange={() => this.handleInputToogle("logout")}
                    />
                    <Typography>Logged me out from all devices</Typography>
                  </div>
                </div>
              </Fade>
            )}
          </div>
          <div className={classes.footer}>
            <div>
              {this.state.step === 3 && (
                <>
                  {this.state.seconds === 0 ? (
                    <Typography
                      className={classes.sendCode}
                      onClick={this.handleSendCode}
                    >
                      Resend Code?
                    </Typography>
                  ) : (
                    <Typography>{this.state.seconds} seconds</Typography>
                  )}
                </>
              )}
            </div>
            <div>
              <ButtonBase
                disableRipple
                className={classes.primaryButton}
                onClick={this.handleNext}
              >
                {this.state.isSubmitLoading === true ? (
                  <CircularProgress color="inherit" size={36} />
                ) : (
                  <Typography style={{ fontWeight: 500 }}>
                    {this.state.step === 5 ? <>Login</> : <>Continue </>}
                  </Typography>
                )}
              </ButtonBase>
              <ButtonBase
                disableRipple
                className={classes.secondaryButton}
                onClick={() => this.setState({ showClose: true })}
              >
                <Typography style={{ fontWeight: 500 }}>Cancel</Typography>
              </ButtonBase>
            </div>
          </div>
          {this.state.showToast !== null && (
            <Snackbar
              open={this.state.showToast !== null}
              autoHideDuration={3000}
              onClose={() => this.setState({ showToast: null })}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert
                variant="standard"
                onClose={() => this.setState({ showToast: null })}
                severity={this.state.showToast.status}
              >
                {this.state.showToast.message}
              </Alert>
            </Snackbar>
          )}
          {this.state.showClose && (
            <SuccessModal
              heading="Confirmation"
              message=" Are you sure you want to exit?"
              buttonText="Yes"
              handleSubmit={() => this.props.handleClose()}
              handleCancel={() => this.setState({ showClose: false })}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  };
}

const materialStyles = (theme) => ({
  paper: {
    borderRadius: "0.62rem",
    width: "100%",
  },
  head: {
    borderBottom: "1px solid #DEE2E6",
    padding: "1rem 2rem",
  },
  // Main title
  title: {
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: "0.5rem",
  },
  body: {
    minHeight: "7rem",
    boxSizing: "border-box",
    "& > :first-child": {
      marginTop: "1rem",
    },
    "& .MuiTextField-root > .MuiFormHelperText-root": {
      marginLeft: 2, marginRight: 0
    }
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
    marginTop: "1rem"
  },
  sendCode: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  primaryButton: {
    height: "3.125rem",
    width: "10rem",
    textAlign: "center",
    padding: "0 0rem",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "0.25rem",
    marginRight: "1rem",
  },
  secondaryButton: {
    height: "3.125rem",
    padding: "0 3rem",
    backgroundColor: "#DEE2E6",
    color: "#8C8C8C",
    borderRadius: "0.25rem",
  },
  step2Container: {
    display: "flex",
    alignItems: "center",
  },
  securityCodeContainer: {
    "& > :first-child": { marginBottom: "1rem" },
  },
  changePasswordContainer: {
    marginBottom: "1rem",
    "& > *": {
      marginBottom: "1rem",
    },
  },
  logout: {
    display: "flex",
    alignItems: "center",
    "& > :first-child": { marginRight: "1.125rem" },
  },
});

export default withRouter(withStyles(materialStyles)(PasswordReset));