import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogContent,
    Typography,
    DialogActions, Grid, CircularProgress, TextField, makeStyles
} from "@material-ui/core";
import { runValidator } from 'utils/validations';

const styles = makeStyles(theme => ({
    body: {
        padding: "1rem 0rem",
        display: "flex", flexDirection: 'column',
        justifyContent: 'center',
        "& > :nth-child(1)": {
            fontSize: "2rem",
            fontWeight: 800
        },
        "& > div:last-child": {
            display: 'flex',
            marginTop: '0.5rem',
            "& > p:first-child": {
                fontSize: '0.875rem',
                color: 'gray'
            }
        }
    },
    fieldContainer: {
        marginTop: '0.875rem',
        "& .MuiTextField-root > .MuiFormHelperText-root": {
            marginLeft: 2, marginRight: 0
        }
    },
    container: {
        textSelect: "none",
        border: "none",
        height: "3rem",
        padding: '0rem 2rem',
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0.3125rem",
        transition: "box-shadow .2s ease-in",
        color: theme.palette.primary.main,
        backgroundColor: "#E6F0F7"
    },
    footerContainer: {
        padding: "0rem 1rem",
        marginBottom: '1rem'
    },
    controlButtons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        maxWidth: "10rem",
        padding: "0 1rem",
        height: "3.25rem",
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        color: "#fff",
        borderRadius: "0.3125rem",
        cursor: "pointer",
        userSelect: "none",
        position: "relative",
    },
    buttonText: {
        userSelect: "none",
        fontWeight: 500,
        fontSize: "1.125rem",
    },
    sendCode: {
        cursor: "pointer",
        fontSize: '0.875rem',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        paddingLeft: '5px'
    }, 
    sendCodeDisabled: {
        cursor: "default",
        fontSize: '0.875rem',
        paddingLeft: '5px'
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    }
}))

const VerifyCode = props => {
    const classes = styles()
    const { open, handleResendCode, handleClose, handleSubmit, isSubmitLoading, email, field_errors, resend_time } = props;

    const INPUTS = {
        code: {
            rules: { required: true, alphanumeric: true, minLength: 6 },
            errorMessage: "Provide the code. "
        }
    }

    const [code, setCode] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setErrors(field_errors)

        return () => setErrors({})
    }, [field_errors])

    const handleInputChange = (e) => {
        setErrors({})
        setCode(e.target.value);
    };

    const validateAll = () => {
        let clone_errors = errors;
        for (let field in INPUTS) {
            let fieldErrors = runValidator(code, INPUTS[field]);
            if (fieldErrors.length > 0) clone_errors[field] = fieldErrors;
        }
        return clone_errors;
    };

    const handleValidation = (e) => {
        let errors = runValidator(e.target.value, INPUTS[e.target.name]);

        if (errors.length > 0) setErrors({ [e.target.name]: errors })
    };

    const handleSubmitCode = () => {
        let errors = validateAll();

        if (Object.keys(errors).length !== 0) setErrors({ ...errors })
        else handleSubmit(code)
    }

    return (
        <Dialog BackdropProps={{ classes: { root: classes.backDrop } }} fullWidth={true} maxWidth="sm" open={open}>
            <DialogContent>
                <div className={classes.body}>
                    <Typography>Please check your email</Typography>
                    <Typography>We've sent a code to {email ?? "your email"}.</Typography>
                    <div className={classes.fieldContainer}>
                        <TextField
                            required
                            variant="outlined"
                            label="Code"
                            tabIndex={1}
                            name="code"
                            value={code}
                            inputProps={{ maxLength: 6 }}
                            onChange={handleInputChange}
                            onBlur={handleValidation}
                            error={errors.code ? true : false}
                            helperText={errors.code && errors.code}
                        />
                    </div>
                    <div>
                        <Typography>Didn't get a code?</Typography>
                        <Typography
                            className={resend_time === 0 ? classes.sendCode : classes.sendCodeDisabled}
                            onClick={() => resend_time === 0 ? handleResendCode(true) : 1}
                        >
                            Click to Resend {resend_time !== 0 && `in ${resend_time} seconds`}
                        </Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container className={classes.footerContainer}>
                    <Grid item xs={6}>
                        <div
                            className={classes.controlButtons}
                            style={{
                                backgroundColor: "#fff",
                                border: "1px solid #8C8C8C",
                            }}
                            onClick={handleClose}
                        >
                            <Typography
                                className={classes.buttonText}
                                style={{ color: "#8C8C8C", fontWeight: 500 }}
                            >
                                Cancel
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            style={{ marginLeft: "auto" }}
                            className={classes.controlButtons}
                            onClick={handleSubmitCode}
                        >
                            {isSubmitLoading ? (
                                <CircularProgress
                                    color="inherit"
                                    style={{ height: "2rem", width: "2rem" }}
                                />
                            ) : (
                                <Typography className={classes.buttonText}>
                                    Verify
                                </Typography>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

VerifyCode.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
}

export default VerifyCode